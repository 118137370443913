// Core react and plugins
import React, {  useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

import Report from '../../../components/report/admin/report';
import { useCookies } from 'react-cookie';
import HomeConfig from '../../tests/room-configs/home';
import Kitchen from '../../tests/room-configs/kitchen-restaurant';
import Outside from '../../tests/room-configs/outside';
import useFlash from '../../../store/flash';

const ReportContainer = (props) => {

    let encSecret = process.env.REACT_APP_ENC_KEY;
    const urlPath = useParams();
    const [error, setError] = useState(false); // eslint-disable-line
    const [authCookie] = useCookies(['bcpa-ta']);
    const [report, setReport] = useState(false)
    const [isLoading, toggleIsLoading] = useState(true)
    const [locationSlide, setLocationSlide] = useState(false); // eslint-disable-line
    const [locationCount, setLocationCount] = useState(0); // eslint-disable-line
    const [locationNo, setLocationNo] = useState(1); // eslint-disable-line
    const [locationInfo, setLocationInfo] = useState(false); // eslint-disable-line
    const [displayPins, setDisplayPins] = useState(false); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const pinSize = {
        x: 48,
        y: 62
    }

    const navigate = useNavigate();

    const loadReport = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url = process.env.REACT_APP_API_BASE + '/report/get/' + urlPath.reportSlug;
        fetch(url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if(data.rslt === "success" && typeof data.detail !== 'undefined'){
                setReport(data.detail);
                if (data.detail.pins !== null){
                    var locations = 0;
                    for (var i in data.detail.pins) {
                        if (locations === 0) {
                            setLocationSlide(i);
                            setCurrentLocation(i, data.detail);
                        }
                        locations++;
                    }
                    setLocationCount(locations);
                }
            }
            
            toggleIsLoading(false)
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Change the location 
    const changeLocation = (e, direction) => {
        e.preventDefault();

        var newLocationId;
        var no;
        if (direction === "next") {

            // Clicked the next button
            var isPassed = false;
            var first = false;
            no = 0;
            for (var i in report.pins) {
                if (!first) first = i;
                no++;
                if (isPassed) {
                    newLocationId = i;
                    break;
                }
                if (i === locationSlide) {
                    isPassed = true;
                }
            }
            if (typeof newLocationId == 'undefined') {
                newLocationId = first;
                no = 1;
            }

        } else {

            // Clicked the prev button 
            var last = false;
            no = 0;
            for (var j in report.pins) {

                if (j === locationSlide && no !== 0) {
                    newLocationId = last;
                    break;
                }
                last = j;
                no++;

            }
            if (typeof newLocationId == 'undefined') {
                newLocationId = last;
            }

        }

        setLocationSlide(newLocationId);
        setCurrentLocation(newLocationId);
        setLocationNo(no);
    }

    // Current location 
    const setCurrentLocation = (locationId, latestReport = false) => {

        var location;
        locationId = parseInt(locationId);
        if (locationId < 10) {

            location = HomeConfig.find(function (element) {
                return element.locationId === locationId;
            });
            setLocationInfo(location);
                
        } else if (locationId < 30) {

            location = Kitchen.find(function (element) {
                return element.locationId === locationId;
            });
            setLocationInfo(location);

        } else {

            location = Outside.find(function (element) {
                return element.locationId === locationId;
            });
            setLocationInfo(location);

        }

        if (!latestReport) latestReport = report;

        // Check whether we have scaled the pins yet
        if (typeof latestReport.pins[locationId][0].xScaled == "undefined") {
            setDisplayPins(false);
            setTimeout(function(){
                positionPins(latestReport, locationId);
            }, 750);
        }

    }

    const positionPins = (latestReport, locationId) => {

        // Figure out the size of the image with ID bgImg
        var bgImg = document.getElementById("bgImg");
        var bgImgWidth = bgImg.offsetWidth;
        var bgImgHeight = bgImg.offsetHeight;

        // Work out the x and y scaling factors 
        var xScale = bgImgWidth / 1000;
        var yScale = bgImgHeight / 1000;

        // Scale the pin positions + add them to the location data 
        var updatedReport = { ...latestReport };
        for (var i in updatedReport.pins[locationId]) {
            var pin = updatedReport.pins[locationId][i];
            updatedReport.pins[locationId][i].xScaled = pin.xPosn * xScale - (pinSize.x / 2) + 5; // 5 added to account for padding around the image
            // updatedReport.pins[locationId][i].xScaled = 0;
            updatedReport.pins[locationId][i].yScaled = pin.yPosn * yScale - pinSize.y + 5; // 5 added to account for padding around the image
            // updatedReport.pins[locationId][i].yScaled = 0;
        }

        console.log("X Scale: " + xScale);
        console.log("Y Scale: " + yScale);
        console.log("Pin size: " + pinSize.x + "X " + pinSize.y + "Y");
        console.log("Pin Positioned:");
        console.log(updatedReport.pins[locationId]);

        setReport(updatedReport);
        setDisplayPins(true);

    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Report :: TechAssure';
        loadReport()
    },[]);
    /* eslint-enable */

    return (
        <Report
            report={report}
            isLoading={isLoading}
            locationSlide={locationSlide}
            locationCount={locationCount}
            locationNo={locationNo}
            location={locationInfo}
            changeLocation={changeLocation}
            displayPins={displayPins}
        />
    );
}

export default ReportContainer;
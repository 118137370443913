// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../store/flash';

// View components
import { useLocation } from 'react-router-dom';
import AdminManage from '../../components/users/admin-manage';

const AdminManageContainer = (props) => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies(['bcpa-ta']);
    const [permissions,setPermissions] = useState({})
    const { pathname } = useLocation();
    const redirectUrl = "/intro"

    //pages that can be accessed by all user types
    const urlPathsToBypassSecurityCheck = {
        '/admin/manage/users/self': true
    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        if(!reformData.permissions.Results && typeof urlPathsToBypassSecurityCheck[pathname] === 'undefined'){
            setRdr(redirectUrl)
            return
        } else {
            setPermissions(reformData.permissions)
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
    },[])
    /* eslint-enable */

    return (
        <AdminManage
            rdr={ rdr }
            permissions={ permissions }
        />
    );
}

export default AdminManageContainer;

// Core react and plugins
import React, {  useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";

// Utilities
import Fetching from './../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import useAuth from './../../store/auth';
import useFlash from './../../store/flash';
import { useCookies } from 'react-cookie';

import Assessment from '../../components/tests/assessment';

import OutsideConfig from './room-configs/outside';
import KitchenConfig from './room-configs/kitchen-restaurant';
import HomeConfig from './room-configs/home';

const AssessmentContainer = (props) => {
    
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const navigate = useNavigate();

    const [authState, setAuth] = useAuth(false); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie, setCookie, removeCookie] = useCookies(['bcpa-ta']); // eslint-disable-line
    const [bgImg, setBgImg] = useState(false);
    const [configFile, setConfigFile] = useState(false);
    const [hotspotQuestion, setHotspotQuestion] = useState(false);
    const [hotspotQuestionsData, setHotspotQuestionsData] = useState(false);
    const [timer, setTimer] = useState(false); // eslint-disable-line
    const [remaining, setRemaining] = useState(false);
    const [existingPins, setExistingPins] = useState(false);
    const [completedLightbox, setCompletedLightbox] = useState(false);
    const [pinMap, setPinMap] = useState(false);
    const [lastPinDropLoc, setLastPinDropLoc] = useState(false);

    const location = useLocation();

    // Define what the test scene should look like
    const defineScene = () => {

        // Is this a proactice room?
        if (props.isPractice) {
            setConfigFile(HomeConfig);
            setBgImg("/room-imgs/home.jpg");
        } else {
            if (typeof props.isOutside != "undefined" && props.isOutside){
                setConfigFile(OutsideConfig);
                setBgImg("/room-imgs/outside-area.jpg");
                return;
            } 
            setConfigFile(KitchenConfig);
            setBgImg("/room-imgs/kitchen.jpg");
        }

        // Handle the location position
        if (hotspotQuestionsData && location.pathname.indexOf('location') > -1) {
            immediateHotspot();
        }
    }

    // Load up a hotspot immediately after page load 
    const immediateHotspot = () => {

        // Get the hotspot info 
        const hotspotId = parseInt(location.pathname.split('/').pop());

        // Get the detail of the hotspot 
        var hotspot = false;
        if (hotspotId < 10) {
            for (var i in HomeConfig) {
                if (HomeConfig[i].locationId === hotspotId) {
                    hotspot = HomeConfig[i];
                    break;
                }
            }
        } else if (hotspotId < 30) {
            for (var j in KitchenConfig) {
                if (KitchenConfig[j].locationId === hotspotId) {
                    hotspot = KitchenConfig[j];
                    break;
                }
            }
        } else {
            for (var k in OutsideConfig) {
                if (OutsideConfig[k].locationId === hotspotId) {
                    hotspot = OutsideConfig[k];
                    break;
                }
            }
        }

        // If we have a hotspot, then load it up
        if (hotspot) {
            displayHotspotQuestion(hotspot);
        }
    }


    // Load up the hotspot questions in a single go
    // As things stand they are the same for all positions, 
    // but they may not be in the long term, in qhich case this may need to be moved to the hotspot container
    const loadQuestions = () => {

        if (hotspotQuestionsData) {
            return;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/tests/options', {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setHotspotQuestionsData(data.detail);
            
        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            removeCookie('bcpa-ta', { path: '/', secure: true, sameSite: true });
            navigate("/");
        });

    }

    // Check the status of the test
    const checkStatus = () => {
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/tests/status', {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // If the test is already started
            if (data.detail.status === "submitted") {
                navigate("/intro/no-access");
            }

            if (!props.isPractice) setTimer(data.detail.realStart);
            if (!props.isPractice) setupTimer(data.detail.realStart);
            getExistingPins();

        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            removeCookie('bcpa-ta', { path: '/', secure: true, sameSite: true });
            navigate("/");
        });
    }

    // Handle the click on a hotspot
    // When we add in the treatments
    const displayHotspotQuestion = (hotspot) => {
        setHotspotQuestion(hotspot);
    }

    const closeHotspotQuestion = (e) => {
        e.preventDefault();
        setHotspotQuestion(false);
    }

    // Setup the timer to count down from 30 mins (or less if the test started already)
    const setupTimer = (timer) => {
        let timerInterval = setInterval(function() {
            if (timer){
                const now = moment.utc();
                const start = moment.utc(timer);
                const diff = moment.duration(now.diff(start));
                const diffSeconds = diff.asSeconds();
                const remaining = Math.round(1800 - diffSeconds);
                setRemaining(remaining);
                if (remaining <= 0) {
                    clearInterval(timerInterval);
                    assessmentCompleted();
                }
            }
        }, 1000);
    }

    // Retrieve a list of the existing pins for the test
    const getExistingPins = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        let url = (props.isPractice) ? '/tests/practice/pins' : '/tests/real/pins';
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success" && data.detail !== null) {
                setExistingPins(data.detail);
            } else {
                setExistingPins([]);
            }

        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            console.log(error);
        });

    }

    // Retrieve a list of the existing pins for the test
    const getPinMap = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/tests/pin-map", {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success" && data.detail !== null) {
                setPinMap(data.detail);
            }

        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            console.log(error);
        });

    }

    // Add a pin into the existing pins state
    const updateExistingPins = (locationId, pin) => {

        // Set up the new pin
        let newPin = {
            locationId: locationId,
            pinId: pin.pinId,
            pinOptionId: pin.pinOptionId,
            quantId: pin.pinQuantityId,
            xPosn: pin.xPosn,
            yPosn: pin.yPosn
        }

        // Check whether this is an update to an existing pin
        let newExistingPins = { ...existingPins };
        var checkPin;
        if (typeof newExistingPins[locationId] != "undefined") {
            for (var i in newExistingPins[locationId]) {
                checkPin = newExistingPins[locationId][i];
                if (checkPin.pinId === newPin.pinId && checkPin.pinOptionId === newPin.pinOptionId && checkPin.quantId === newPin.quantId) {
                    newExistingPins[locationId][i] = newPin;
                    setExistingPins(newExistingPins);
                    return;
                }
            }
        }

        // If not, then append it to the appropriate poiunt in the existingPins object
        if (typeof newExistingPins[locationId] == "undefined") {
            newExistingPins[locationId] = [];
        }
        newExistingPins[locationId].push(newPin);
        setExistingPins(newExistingPins);
    }

    // Remove a pin from the existing pins state
    const removeExistingPin = (locationId, pin) => {

        // Check that the ocation exists in the existing pins
        let newExistingPins = { ...existingPins };
        if (typeof newExistingPins[locationId] != "undefined") {
            for (var i in newExistingPins[locationId]) {
                if (newExistingPins[locationId][i].pinId === pin.pinId && newExistingPins[locationId][i].pinOptionId === pin.pinOptionId && newExistingPins[locationId][i].quantId === pin.pinQuantityId) {
                    newExistingPins[locationId].splice(i, 1);
                    setExistingPins(newExistingPins);
                    return;
                }
            }
        }
        return;
    }

    // Submit the assessment 
    const submitAssessment = (blockRedirect = false) => {

        if (!props.isPractice) {
            if (!window.confirm("Are you sure you want to submit your assessment?")) {
                return;
            }
        }

        if (typeof blockRedirect != "boolean") {
            blockRedirect.preventDefault();

            blockRedirect = false;
        }

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Check the server side to see what the status of this auth is...
        let url = (props.isPractice) ? '/tests/practice/submit' : '/tests/real/submit';
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (!props.isPractice) {

                // If the test is already started
                if (data.rslt === "success" && data.detail === "pass") {
                    if (!blockRedirect) navigate("/intro/pass");
                    return;
                }

                if (data.rslt === "success" && data.detail === "fail") {
                    if (!blockRedirect) navigate("/intro/fail");
                    return;
                }

                if (!blockRedirect) navigate("/intro/error");
                return

            } 

            if (!blockRedirect) navigate("/intro/practised");
            return

        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            if (!blockRedirect) {
                flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
                removeCookie('bcpa-ta', { path: '/', secure: true, sameSite: true });
                navigate("/");
            }
        });

    }

    // Display a lightbox to direct them back to the intro page
    const assessmentCompleted = () => {
        setCompletedLightbox(true);
        submitAssessment(true);
    }
   
    /*eslint-disable */
    useEffect(() => {
        checkStatus();
        defineScene();
        loadQuestions();
        getPinMap();
        document.title = 'Assessment :: TechAssure';
    },[]);
    useEffect(() => {
        defineScene();
    }, [location]);
    /*eslint-enable */

    return (
        <Assessment 
            isSitemap={ props.isSitemap }
            isPractice={ props.isPractice }
            remaining={ remaining }
            questionData={ hotspotQuestionsData }
            configFile={ configFile }
            bgImg={ bgImg }
            hotspotQuestion={ hotspotQuestion }
            displayHotspotQuestion={ displayHotspotQuestion }
            closeHotspotQuestion={closeHotspotQuestion }
            existingPins={ existingPins }
            updateExistingPins={ updateExistingPins }
            removeExistingPin={ removeExistingPin }
            submitAssessment={ submitAssessment }
            completedLightbox={ completedLightbox }
            pinMap={ pinMap }
            lastPinDropLoc={ lastPinDropLoc }
            setLastPinDropLoc={ setLastPinDropLoc }
        />
    );
}

export default AssessmentContainer;
const Kitchen = [
    {
        locationId: 10,
        i: -50, // horizontal rotation clockwise in degrees?
        a: 15,//vertical rotation in degrees?
        text: "Previously this linked to the site plan PDF. Is it still required as a hostpsot?",
        type: "info-icon",
        img: "/img/zooms/pest-log.png"
    },
    {
        locationId: 11,
        i: -55,
        a: 50,
        text: "Below freezer",
        type: "info",
        img: "/img/zooms/below_freezer.jpg"
    },
    {
        locationId: 12,
        i: -29,
        a: 45,
        text: "Bottom of Door",
        type: "info",
        img: "/img/zooms/below_door.jpg"
    },
    {
        locationId: 13,
        i: -20,
        a: 95,
        text: "Cabinet Bottom - green tape",
        type: "info",
        img: "/img/zooms/below_card_cups.jpg"
    },
    {
        locationId: 14,
        i: 55,
        a: -58,
        text: "Bottom of shelf - pots pans",
        type: "info",
        img: "/img/zooms/below_utensils.jpg"
    },
    {
        locationId: 15,
        i: 125,
        a: -47,
        text: "Bottom of shelf - oil tub",
        type: "info",
        img: "/img/zooms/below_table.jpg"
    },
    {
        locationId: 16,
        i: 138,
        a: -55,
        text: "Bottom of shelf - food",
        type: "info",
        img: "/img/zooms/corner.jpg"
    },
    {
        locationId: 17,
        i: -147,
        a: 75,
        text: "Bottom of shelf - vent",
        type: "info",
        img: "/img/zooms/big_freezer.jpg"
    },
    {
        locationId: 18,
        i: -128,
        a: 48,
        text: "Bin stand",
        type: "info",
        img: "/img/zooms/dustbin.jpg"
    },
    {
        locationId: 19,
        i: -108,
        a: 44,
        text: "Chemical soap",
        type: "info",
        img: "/img/zooms/near_soap.jpg"
    },
    {
        locationId: 20,
        i: -22,
        a: 0,
        type: "link",
        lnk: "/assessment/outside",
        img: "/img/zooms/pest-logs.jpg"
    },
];


export default Kitchen;
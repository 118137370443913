// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import CreateClient from '../../../components/users/clients/create';
import { useParams } from 'react-router-dom';

const CreateClientContainer = (props) => {

    const urlPath = useParams();

    let isClientEdit = true;
    let initialBtnText = "Edit Client"
    if(typeof urlPath != "undefined" && urlPath.clientRef === 'create'){
        isClientEdit = false;
        initialBtnText = "Create Client"
    }

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false); // eslint-disable-line
    const [title, setTitle] = useState("Create Client"); // eslint-disable-line
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ name:"" });
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies(['bcpa-ta']);
    
    const redirectUrl = "/intro"

    // Handle the field updates 
    const handleUpdate = (fieldname, e) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = e.target.value;
        setSettings(newSettings);
    }

    
    // Submit the details for saving
    const saveClient = ( e ) => {

        e.preventDefault();
        setError(false);

        var nbtn = (!isClientEdit) ? "Creating Client..." : "Editing Client...";
        setBtnTxt(nbtn);



        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        var url = (!isClientEdit) ? '/clients':  '/clients/' +urlPath.clientRef;
        var methodType = (!isClientEdit) ? 'POST' : 'PUT';
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: methodType ,
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify({name:settings['name']})
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            var nbtn;
            if(data.rslt === 'invalid-name'){
                setError({ msg: "Sorry but there was an error creating the client. Please check your data and try again", style: "red" });
                setValErrors({'name':['The name is invalid']});
                nbtn = (isClientEdit) ? "Edit Client" : "Create Client";
                setBtnTxt(nbtn);
                return;
            } else if (data.rslt === 'existing-client'){
                setError({ msg: "Sorry but there was an error creating the client. Please check your data and try again", style: "red" });
                setValErrors({'name':['The client already exists']});
                nbtn = (isClientEdit) ? "Edit Client" : "Create Client";
                setBtnTxt(nbtn);
                return;
            }

            nbtn = (isClientEdit) ? "Edit Client" : "Create Client";
            setBtnTxt(nbtn);

            var message = (isClientEdit) ? "The client has been updated" : "A new client has been created" ;

            setError({ msg: message, style: "grn" });
            flashActions.set({ msg: message, style: "grn" });
            setRdr("/admin/manage/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // Load up existing user details
    const loadClient = () => {
        // Check whether there is an edit param set up
        if(!isClientEdit){
            return;
        }

        setLoading(true);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined") {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }

        // Get the user details from the server
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8)); 
        
        fetch(process.env.REACT_APP_API_BASE + '/clients/' + urlPath.clientRef, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if(data.rslt === 'success'){
                setSettings({'name':data.detail.clientName})
                setLoading(false);
            }
           

        })
        .catch(function (error) {
            flashActions.set({ msg: "Sorry but we could not find the user you are looking for. PLease try again.", style: "red" });
            setRdr('/admin/manage/clients');
        });        
    }

    /* eslint-disable */
    // useEffect(() => {
    //     setupTitle();
    //     loadUsr();
    //     setTimeout(function(){
    //         loadTimezones();
    //         getGrpOpts();
    //     }, 250); 
    // }, []);
    /* eslint-enable */

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        if(!reformData.permissions.Clients){
            setRdr(redirectUrl)
            return
        } else if (isClientEdit){
            loadClient(urlPath.clientRef)
        }
    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Create client :: TechAssure';
        checkUserPermissions()
    },[])
    /* eslint-enable */

    return (
        <CreateClient
            // error={ error }
            valErrors={ valErrors }
            rdr={ rdr }
            handleUpdate={handleUpdate}
            saveClient={saveClient}
            isClientEdit={isClientEdit}
            btnTxt={btnTxt}
            // loading={ loading }
            settings={ settings }
            // handleUpdate={ handleUpdate }
            // saveUser={ saveUser }
            // groups={ groups }
            // title={ title }
            // btnTxt={ btnTxt }
            // timezones={ timezones }
        />
    );
}

export default CreateClientContainer;

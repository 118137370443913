import moment from 'moment';
import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Error from '../../common/forms/error';
import Loader from '../../common/loader';

function ClientList(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <Fragment>
            <section className="width-12 centred">
                <Link to='/admin/manage/clients/create' className='btn minor'>Create</Link>
                <h1>Manage companies</h1>

                <div className="filter">
                    <h4>Search companies</h4>

                    <div className="input text">
                        <label htmlFor='searchInput'>Company name</label>
                        <input type="text" id="searchInput" onChange={(e) => props.setSearchEntry(e.target.value)} />
                    </div>

                    <button onClick={() => props.searchClients()}>Search</button>
                </div>
                
                <Error error={ props.error } />
                <div className="table-wrap" onScroll={props.handleScroll}>
                    <table>
                        <thead>
                            <tr>
                                <th>Company name</th>
                                <th>Employee no.</th>
                                <th className='med-hide'>Created</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typeof props.clients != "undefined" && props.clients.map( (client,i) => {
                                    
                                    return (
                                        <tr key={'tr-'+i}>
                                            <td>{client.clientName}</td>
                                            <td>{ client.employeeCount }</td>
                                            <td className="med-hide">{ moment(client.created).format('DD/MM/YYYY') }</td>
                                            <td>
                                                <Link to={'/admin/manage/users/create/' + client.id} className="action-btn sml-hide">Create employee</Link>
                                                <Link to="#export" className="action-btn med-hide" onClick={ (e) => props.toggleExportLb(e, client.id, client.clientName) }>Export</Link>
                                                <Link to={'/admin/manage/clients/'+client.id} className="action-btn">Edit</Link>
                                                <Link to='#' onClick={(e) => props.deleteClient(client.id,e)} className="action-btn sml-hide red">Delete</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    { props.loading && 
                        <Loader />
                    }
                </div>

            </section>
  
            { props.exportLb && 
                <div className="cover">
                    <div className="hover-box high">
                        <Link to="#close" className="close-lnk" onClick={ (e) => props.toggleExportLb(e) }>Close</Link>
                        <h2>Export data: { props.exportLb.name }</h2>
                        <p>Select the fields you would like to include in your CSV file, then select &quot;Export&quot;.</p>

                        <ul className="columns">
                            <li className="input checkbox">
                                <input type="checkbox" id="firstName" onChange={ (e) => props.setExportSetting(e, "firstName") } checked={ props.exportSettings.firstName } />
                                <label htmlFor="firstName">First name</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="lastName" onChange={(e) => props.setExportSetting(e, "lastName")} checked={props.exportSettings.lastName} />
                                <label htmlFor="lastName">Last name</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="emailAddress" onChange={(e) => props.setExportSetting(e, "emailAddress")} checked={props.exportSettings.emailAddress} />
                                <label htmlFor="emailAddress">Email address</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="companyName" onChange={(e) => props.setExportSetting(e, "companyName")} checked={props.exportSettings.companyName} />
                                <label htmlFor="companyName">Company name</label>
                            </li>

                            <li className="input checkbox">
                                <input type="checkbox" id="assessmentDate" onChange={(e) => props.setExportSetting(e, "assessmentDate")} checked={props.exportSettings.assessmentDate} />
                                <label htmlFor="assessmentDate">Assessment date</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="assessmentStart" onChange={(e) => props.setExportSetting(e, "assessmentStart")} checked={props.exportSettings.assessmentStart} />
                                <label htmlFor="assessmentStart">Assessment start</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="assessmentDuration" onChange={(e) => props.setExportSetting(e, "assessmentDuration")} checked={props.exportSettings.assessmentDuration} />
                                <label htmlFor="assessmentDuration">Assessment duration</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="assessmentResult" onChange={(e) => props.setExportSetting(e, "assessmentResult")} checked={props.exportSettings.assessmentResult} />
                                <label htmlFor="assessmentResult">Assessment result</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="minors" onChange={(e) => props.setExportSetting(e, "minors")} checked={props.exportSettings.minors} />
                                <label htmlFor="minors">Minor errors</label>
                            </li>
                            <li className="input checkbox">
                                <input type="checkbox" id="majors" onChange={(e) => props.setExportSetting(e, "majors")} checked={props.exportSettings.majors} />
                                <label htmlFor="majors">Major errors</label>
                            </li>
                        </ul>

                        <button onClick={ (e) => props.runExport(e) }>{ props.exportLabel }</button>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default ClientList
import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import FooterContainer from '../containers/common/footer-container';
import HeaderContainer from '../containers/common/header-container';
import IntroContainer from '../containers/tests/intro-container';
import InstructionsContainer from '../containers/tests/instructions-container';
import AssessmentContainer from '../containers/tests/assessment-container';
import ReportContainer from '../containers/reports/admin/report-container';
import Errors from './common/errors';
import AdminManageContainer from '../containers/users/admin-manage-container';
import SettingsContainer from '../containers/users/auth/change-details-container';

class AuthRouting extends Component {

    render () {
        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        return (
            <div className={ this.props.isAdmin ? "whole" : "whole textured" }>
                { this.props.isAdmin ? (
                    <HeaderContainer layout={ "admin" } />
                ) : (
                    <HeaderContainer />
                )}
                <div className="main-content">
                    <Routes>
                        <Route exact path='/intro' element={<IntroContainer />} />
                        <Route exact path='/intro/pass' element={<IntroContainer justPassed={true} />} />
                        <Route exact path='/intro/fail' element={<IntroContainer justFailed={true} />} />
                        <Route exact path='/intro/error' element={<IntroContainer justErrored={true} />} />
                        <Route exact path='/intro/practised' element={<IntroContainer justPractised={ true } />} />
                        <Route exact path='/practice/instructions' element={<InstructionsContainer isPractice={true} />} />
                        <Route exact path='/assessment/instructions' element={<InstructionsContainer />} />
                        <Route exact path='/assessment/outside' element={<AssessmentContainer isPractice={false} isOutside={ true } />} />
                        <Route exact path='/practice' element={<AssessmentContainer isPractice={true} />} />
                        
                        <Route exact path='/assessment' element={<AssessmentContainer />} />
                        <Route exact path='/practice/sitemap' element={<AssessmentContainer isPractice={true} isSitemap={true} />} />
                        <Route exact path='/practice/location/:locationId' element={<AssessmentContainer isPractice={true} />} />
                        <Route exact path='/assessment/sitemap' element={<AssessmentContainer isSitemap={true} />} />
                        <Route exact path='/assessment/location/:locationId' element={<AssessmentContainer isPractice={true} />} />
                        <Route exact path='/reports/:reportSlug' element={<ReportContainer />} />
                        <Route exact path='/settings' element={<SettingsContainer/>}/>
                        
                        {/* Admin routes */}
                        <Route path='/manage/*' element={<AdminManageContainer/>}/>

                        {/* Error routes */}
                        <Route path='*' element={<Errors/>} /> 
                    </Routes>
                </div>

                <FooterContainer/>
            </div>
        );
    }
}

export default AuthRouting;

import {useState} from 'react';
import { useDrag } from 'react-dnd'
import { ItemTypes } from './itemTypes.js'

export const Pin = ({ id, left, top, children, img, title, isBig, removePin, removeLb, cancelRemoval, completeRemoval }) => {
    const [startTime, setStartTime] = useState(false);
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.BOX,
            item: { id, left, top },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top],
    )
    if (isDragging) {
        if (removeLb && isBig) cancelRemoval(false);
        return <div ref={drag} />
    }

    const longPress = (e, evt) => {
        if (e.buttons === 2 || e.button === 2) return;
        if (e.target.id === "remBtn") return;
        if (evt === "start") {
            // Track the time to the nearest millisecond
            setStartTime(new Date().getTime());
            if (isDragging) {
                setStartTime(false);
            }
        } else if (evt === "end" && startTime) {
            // Calculate the time difference between the start and end times
            let endTime = new Date().getTime();
            let timeDiff = endTime - startTime;
            // If the time difference is greater than 1 second, then it's a long press
            if (timeDiff > 650) {
                removePin(false, id);
            } else {
                if (typeof cancelRemoval != "undefined") {
                    cancelRemoval(e)
                }
            }
        }

    }

    return (
        <div
            className={ typeof isBig != "undefined" && isBig ? "pin pin-big" : "pin"}
            ref={drag}
            style={{ left, top, backgroundImage: 'url("/img/pins/' + img + '")' }}
            data-testid="box"
            title={ title }
            onMouseDown={(e) => longPress(e, "start")}
            onMouseUp={(e) => longPress(e, "end")}
        >
            <button className="pin-delete" title="Remove pin" onContextMenu={(e) => removePin(e, id) }>Del</button>
            {children}
            { removeLb && removeLb === id && 
                <div className="pin-remove-lb">
                    <h6>Remove pin</h6>
                    <p>Are you sure you want to remove &quot;{ title }&quot;?</p>
                    <button className="btn btn-danger" id="remBtn" onClick={(e) => completeRemoval(e, id)}>Remove</button>
                    <button className="btn btn-cancel" onClick={(e) => cancelRemoval(e)}>Cancel</button>
                </div>
            }
        </div>
    )
}
import React from 'react';
import UserListContainer from '../../containers/users/index-container';
import CreateClientContainer from '../../containers/users/clients/create-container';
import CreateContainer from '../../containers/users/create-container';
import ClientListContainer from '../../containers/users/clients/index-container';
import { Routes,Route } from 'react-router-dom';

import { Navigate } from 'react-router-dom';
import ChangeDetailsContainer from '../../containers/users/auth/change-details-container';
import BatchCreateContainer from '../../containers/users/batch-create-container';
import Errors from '../common/errors';
import DashboardContainer from '../../containers/users/dashboard-container';

function AdminManage(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <div className="admin-container">

            <div className="content core-admin">
                <Routes>
                    <Route index element={<DashboardContainer />} />
                    <Route path='/dashboard' element={<DashboardContainer />} />
                    <Route path='/users' element={<UserListContainer />} />
                    <Route exact path='/users/upload' element={<BatchCreateContainer />} />
                    <Route path='/users/:userRef' element={<CreateContainer />} />
                    <Route path='/users/:userRef/:clientRef' element={<CreateContainer />} />
                    <Route path='/clients' element={<ClientListContainer/>} />
                    <Route path='/users/self' element={<ChangeDetailsContainer/>} />
                    <Route path='/clients/:clientRef' element={<CreateClientContainer/>} />
                    <Route path='*' element={<Errors />} /> 
                </Routes>
            </div>
          
        </div>
    )
}

export default AdminManage
// TODO: Handling CSRF attacks?!?!
// Submit an MFA token to complete login

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';
import moment from 'moment';

import ClientList from '../../../components/users/clients'
const ClientListContainer = () => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [llEnabled, setLlEnabled] = useState(true); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [error, setError] = useState(false);
    const [clients, setClients] = useState([]);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies(['csirt']);
    const [nextPage, setNextPage] = useState(1); // eslint-disable-line
    const [searchEntry, setSearchEntry] = useState(""); // eslint-disable-line
    const [searchActive, setSearchActive] = useState(false); // eslint-disable-line

    const [exportLb, setExportLb] = useState(false); // Display the export lightbox
    const [exportSettings, setExportSettings] = useState({
        firstName: true,
        lastName: true,
        emailAddress: true,
        companyName: true,
        assessmentDate: true,
        assessmentStart: true,
        assessmentDuration: true,
        assessmentResult: true,
        minors: true,
        majors: true
    }); // Settings for the export
    const [exportLabel, setExportLabel] = useState("Export data"); // Data to export


    let loginUrl = "/";
    const redirectUrl = "/intro"

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadClients = (queryStr) => {

        if (loading) return;
        if (!llEnabled && nextPage > 1) return;
        setLoading(true);

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        var reqDetail = {
            method: "get",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        };
        var page = nextPage;
        if ((typeof queryStr != "undefined" && queryStr !== "") || (searchActive && searchEntry !== "")) {
            if (typeof queryStr != "undefined" && queryStr !== "") {
                setClients([]);
                setNextPage(1);
                setLlEnabled(true);
                page = 1;
            }
            reqDetail.method = "post";
            reqDetail.body = JSON.stringify({ query: searchEntry });
            setSearchActive(true);
        } else {
            setSearchActive(false);
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients?limit=25&page=' + page, reqDetail)
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false);
            if (data.length === 0) {
                setLlEnabled(false);
            } else if (page === 1) {
                setClients(data)
            } else {
                // This is an update via lazy load 
                var newClients = [...clients];
                for (var i in data) {
                    newClients.push(data[i]);
                }
                setClients(newClients);
            }
            setNextPage(page + 1)
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    const searchClients = () => {
        loadClients(searchEntry);
    }

    const toggleExportLb = (e, clientId, clientName) => {
        e.preventDefault();
        var newExportLb = (exportLb) ? false : { id: clientId, name: clientName};
        setExportLb(newExportLb);
    }

    const setExportSetting = (e, setting) => {

        var newSettings = {...exportSettings}
        newSettings[setting] = (newSettings[setting]) ? false : true;
        setExportSettings(newSettings);

    }

    // Export the data to CSV
    const runExport = (e) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        setExportLabel("Exporting data...");

        var reqDetail = {
            method: "post",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(exportSettings)
        };

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients/' + exportLb.id + "/export", reqDetail)
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                objectToCsv(data.detail);
                return;
            }
            alert("Sorry but there was an error processing the data. Please try again.");
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });

    }

    // Export the returned data as a CSV file
    const objectToCsv = (data) => {
        var csv = "data:text/csv;charset=utf-8,";

        // Add in the header row 
        const headerNames = {
            firstName: "First name",
            lastName: "Last name",
            emailAddress: "Email address",
            companyName: "Company name",
            assessmentDate: "Assessment date",
            assessmentStart: "Assessment start time",
            assessmentDuration: "Assessment duration",
            assessmentResult: "Assessment result",
            minors: "Minor errors",
            majors: "Major errors",
        }
        for (var key in exportSettings) {
            if (exportSettings[key]) {
                csv += headerNames[key] + ",";
            }
        }
        csv += "\n";

        // Loop through the rows and start adding them
        for (var i in data) {
            for (var j in exportSettings) {
                if (exportSettings[j]) {
                    if (j === "assessmentDuration") {
                        csv += moment.duration(data[i][j], "seconds").format("mm:ss", { trim: false }) + ",";
                    } else {
                        csv += data[i][j] + ",";
                    }
                }
            }
            csv += "\n";
        }

        setExportLabel("Export data");

        // Run the export of the CSV file
        var encodedUri = encodeURI(csv);
        var lnk = document.createElement("a");
        lnk.setAttribute("href", encodedUri);
        lnk.setAttribute("download", "techassure-company-export.csv");
        document.body.appendChild(lnk);

        lnk.click();
    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        document.title = 'Manage clients :: TechAssure';
    }, []);
    /* eslint-enable */

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            setRdr("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        if(!reformData.permissions.Clients){
            setRdr(redirectUrl)
            return
        } else {
            loadClients()
        }
    }

    const deleteClient = ( clientId,e ) => {

        e.preventDefault();
        setError(false);

        let doDelete = window.confirm('Do you wish to delete this client?  This cannot be undone');

        if(!doDelete){
            return false;
        }
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            flashActions.set({ msg: "Sorry but we could not find the page you are looking for. PLease sign in and try again.", style: "red" });
            setRdr("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        var url =   '/clients/' +clientId;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'delete',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            

            setError({ msg: 'The client has been deleted', style: "grn" });
            loadClients()
            // flashActions.set({ msg: message, style: "grn" });
            // setRdr("/admin/manage/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the client", style: "red" });
        });

    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) >= (e.target.clientHeight - 20);
        if (bottom) { 
            loadClients();
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
    },[])
    /* eslint-enable */

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    return (
        <ClientList
            error={ error }
            rdr={ rdr }
            clients={clients}
            deleteClient={deleteClient}
            handleScroll={ handleScroll }
            loading={ loading }
            searchClients={ searchClients }
            setSearchEntry={ setSearchEntry }
            toggleExportLb={ toggleExportLb }
            exportLb={ exportLb }
            exportSettings={ exportSettings }
            setExportSetting={ setExportSetting }
            exportLabel={ exportLabel }
            runExport={ runExport }
        />
    );
}

export default ClientListContainer;

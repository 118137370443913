function Footer(props){

    return (
        <footer>
            <div className="centred">          
                <p>&copy; 2022 TechAssure. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
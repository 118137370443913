import React from 'react';
import moment from "moment";
import {duration} from "moment-duration-format";

function Timer(props){

    // Figure out the time remaining (in a nice format)
    const timeRemaining = (props.remaining > 0) ? moment.duration(props.remaining, "seconds").format("mm:ss", { trim: false }) : "Finished!";

    return (
        <div className="timer">
           <p>{timeRemaining}</p>
        </div>
    )
}

export default Timer
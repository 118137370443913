// Core react and plugins
import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';
import Instructions from '../../components/tests/instructions';

const InstructionsContainer = (props) => {
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [authCookie] = useCookies(['bcpa-ta']);

    const [lb, setLb] = useState(false);

    const navigate = useNavigate();

    // Handle the click on the start button
    // Store the fact that they have started a test
    const handleStart = (e) => {
        e.preventDefault();

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url = (props.isPractice) ? "/tests/practice" : "/tests/real";
        
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if (data.rslt === 'success' || data.rslt === 'already-started-test') {
                let rdr = (props.isPractice) ? "/practice" : "/assessment";
                navigate(rdr);
            } else {
                navigate("/intro");
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
            navigate("/intro");
        });

    }

    const toggleLb = (e) => {
        e.preventDefault();
        setLb(!lb);
    }
   
    /*eslint-disable */
    useEffect(() => {
        document.title = 'Instructions :: TechAssure';
    },[])
    /*eslint-enable */

    return (
        <Instructions 
            handleStart={ handleStart }
            isPractice={ props.isPractice }
            lb={ lb }
            toggleLb={ toggleLb }
        />
    );
}

export default InstructionsContainer;
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Intro(props){

    return (
        <Fragment>
            {props.displayVideo &&
                <div className="cover">
                    <div className="hover-box">
                        <Link to='' onClick={(e) => props.launmchVideo(e, false)} className="close-lnk">Close</Link>
                        <iframe title="How to use TechAssure" width="100%" height="auto" style={{ minHeight: 400 }} src="https://www.youtube.com/embed/Nxe85K1mNQ8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            }

            <section className="intro">
                <div className="content">
                    <div className="width-6 centred">
                        <h1>Welcome</h1>
                        <p>Welcome to the BPCA&rsquo;s virtual reality assessment portal. This entry level assessment uses technology to create a real life, interactive scenario aimed at testing your knowledge and understanding of:</p>
                        <ol>
                            <li>Identifying the type of rodent involved</li>
                            <li>Finding the root cause of the infestation</li>
                            <li>Selecting control methods to safely and effectively bring the infestation under control</li>
                            <li>Indicating recommendations or proofing options to prevent any re-occurrence.</li>
                        </ol>
                    </div>
                </div>
            </section>

            <section className="steps">
                {props.error &&
                    <div className={"flash-msg " + props.error.style}>
                        <p>{props.error.msg}</p>
                    </div>
                }

                <div className="step video">
                    <div className="head-img video"></div>
                    <div className="wording">
                        <h3>How it works</h3>
                        <p>Watch our short video to learn how TechAssure works. We&rsquo;ll show you have to navigate your way
                            through the site and demonstrate how to use the tools available to help you complete the
                            assessment.</p>
                    </div>
                    <Link to="/video" className="cover-btn" onClick={ props.launchVideo }>View</Link>
                    <Link to="/video" className="onward-btn" onClick={ props.launchVideo }>View</Link>
                </div>

                <div className={ "step practice " + props.practiceStatus }>
                    <div className="head-img practice"></div>
                    <div className="wording">
                        <h3>Practice room</h3>
                        <p>Using what you&rsquo;ve learned in the video, spend as much time as you like in the practice room getting
                            used to moving around and using the controls. There is no time limit here and anything you do will
                            have no bearing on your assessment.</p>
                    </div>
                    { props.practiceStatus !== "submitted" &&
                        <Link to="#assessment" className="cover-btn" onClick={(e) => props.handleRedirect(e, "practice")} title={props.practiceLabel}>View</Link>
                    }
                    <Link to="#assessment" className="onward-btn" onClick={(e) => props.handleRedirect(e, "practice")} title={ props.practiceLabel }>View</Link>
                </div>
                
                <div className={"step " + props.realStatus + " " + props.status.realRslt}>
                    <div className="head-img real">
                        { props.realStatus === "submitted" &&
                            <div className="step-status">
                                <p dangerouslySetInnerHTML={{ __html: props.realLabel.replace(/(?:\r\n|\r|\n)/g, "<br />")}}></p>
                            </div>
                        }
                    </div>
                    <div className="wording">
                        <h3>Timed assessment</h3>
                        <p>When you are ready, enter the assessment site. Before you begin the assessment you will be given
                            some important information. Take your time to read this. Once you are ready to proceed click on the
                            &quot;Get Started&quot; button.</p>
                    </div>
                    <Link to="#assessment" className="cover-btn" onClick={(e) => props.handleRedirect(e, "real")} title={props.realLabel}>View</Link>
                    <Link to="#assessment" className="onward-btn" onClick={(e) => props.handleRedirect(e, "real")} title={props.realLabel}>View</Link>
                </div>
            </section>
        </Fragment>
    )
}

export default Intro
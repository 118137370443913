import React, {useState} from 'react';
import { Link } from 'react-router-dom';

// Assets
import Logo from './../../assets/img/bpca/bpca-logo.png';

function Header(props){

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <header>
            <div className="content">
                <div className='width-3 logo-sxn left'>
                    <img src={Logo} alt="BPCA logo" />
                    <h2>TechAssure</h2>
                </div>
                { !props.hide &&
                    <div className='width-9 right'>
                        <nav>
                            <Link className="mobile-menu" to='#menu' onClick={(e) => toggleMenu(e)}>Menu</Link>
                            <ul className={ menuOpen ? "open": "" }>
                                {props.permissions.Tests &&
                                    <li><Link to='/intro'>Assessment</Link></li>
                                }
                                <li><Link to='/settings'>Settings</Link></li>
                                {props.permissions.Results &&
                                    <li><Link to='/admin/manage/dashboard'>Manage</Link></li>
                                }
                                <li><Link to='/u/logout'>Logout</Link></li>
                            </ul>
                        </nav>
                    </div>
                }
            </div>
        </header>
    )
}

export default Header
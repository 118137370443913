// Core react and plugins
import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';
import Intro from '../../components/tests/intro';

// // State management
import { useCookies } from 'react-cookie';


const IntroContainer = (props) => {
    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [rdr, setRdr] = useState(false);// eslint-disable-line
    const [displayVideo, setVideoStatus] = useState(false);// eslint-disable-line
    const [fadeClass, setFadeClass] = useState("");// eslint-disable-line
    const [fadeClassBtm, setFadeClassBtm] = useState("");// eslint-disable-line
    const [authCookie] = useCookies(['bcpa-ta']);
    const [status,setStatus] = useState(false);
    const [practiceStatus, setPracticeStatus] = useState("");
    const [practiceLabel, setPracticeLabel] = useState("");
    const [realStatus, setRealStatus] = useState("");
    const [realLabel, setRealLabel] = useState("");
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    
    const checkQuestionnaireStatus = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        
        fetch(process.env.REACT_APP_API_BASE + '/tests/status', {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success'){
                setStatus(data.detail)

                var newPracticeLabel = "No yet started";
                if (data.detail.practice === "submitted") {
                    newPracticeLabel = "Practice assessment completed";
                } else if (data.detail.practice === "in_progress") {
                    newPracticeLabel = "Assessment not yet completed";
                }
                setPracticeLabel(newPracticeLabel);

                var newRealLabel = "No yet started";
                if (data.detail.real === "submitted") {
                    newRealLabel = "Assessment result: " + capitaliseFirstLetter(data.detail.realRslt) + "\n";
                    newRealLabel += "Minor errors: " + data.detail.realMinors + "\n";
                    newRealLabel += "Major errors: " + data.detail.realMajors;
                } else if (data.detail.real === "in_progress") {
                    newRealLabel = "Assessment not yet completed";
                }
                setRealLabel(newRealLabel);
            }
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    // Function to capitalise the first letter of a string
    const capitaliseFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const updateTestStatuses = () => {

        // Practice status 
        if (status.practice === "submitted") {
            setPracticeStatus("submitted");
        } else if (status.practice === "in_progress") {
            setPracticeStatus("in-progress");
        }

        // Real status
        if (status.practice !== "submitted") {
            setRealStatus("faded");
        } else {
            if (status.real === "submitted") {
                setRealStatus("submitted");
            } else if (status.real === "in_progress") {
                setRealStatus("in-progress");
            } else {
                setRealStatus("not-started");
            }
        }
    }

    const launchVideo = (e, isOpen = true) => {
        e.preventDefault();
        setVideoStatus(isOpen)
    }

    // Handle the redirect to the assessment 
    const handleRedirect = (e, testType) => {
        e.preventDefault();

        // Handle the redirect to practice assessments 
        if (testType === "practice") {
            if (status.practice === "submitted") {
                setError({ msg: "Sorry, but you have already completed the practice assessment so you can no longer access this page.", style: "error" });
                return;
            }
            navigate('/practice/instructions');       
        }

        // Handle the redirect to real assessments
        if (testType === "real") {
            if (status.practice !== "submitted") {
                setError({ msg: "Sorry, but you need to complete the practice assessment before you can proceed to the live assessment.", style: "error" });
                return;
            }

            if (status.real === "submitted") {
                setError({ msg: "Sorry, but you have already completed this assessment. If you would like to try again, please contact the TechAssure team (training@bpca.org.uk) to reset your account.", style: "error" });
                return;
            }

            navigate('/assessment/instructions');       

        }

    }

    // Check whether we need to display a flash message straight away 
    const checkFlashMessage = () => {

        if (typeof props.justPassed != "undefined" && props.justPassed) {
            setError({ msg: "Congratulations! You have passed the assessment.", style: "grn" });
        }

        if (typeof props.justFailed != "undefined" && props.justFailed) {
            setError({ msg: "We're sorry, but you just failed the assessment. If you would like to try again, please contact the TechAssure team: training@bpca.org.uk", style: "red" });
        }

        if (typeof props.justErrored != "undefined" && props.justErrored) {
            setError({ msg: "We're sorry, but there was an error submitting the assessment. Please re-enter the asessment and try submitting again. If the error persists please contact the TechAssure team: training@bpca.org.uk", style: "red" });
        }

        if (typeof props.justPractised != "undefined" && props.justPractised) {
            setError({ msg: "Congratulations, you have completed the practice assessment. You can now take the timed assessment .", style: "grn" });
        }

    }
   
    /*eslint-disable */
    useEffect(() => {
        updateTestStatuses();
    }, [status])

    useEffect(() => {
        document.title = 'Welcome :: TechAssure';
        checkFlashMessage();
        if (!status) {
            checkQuestionnaireStatus();
        }
    },[])
    /*eslint-enable */

    return (
        <Intro 
            status={status}
            realStatus={realStatus}
            practiceStatus={practiceStatus}
            setFadeClass={ setFadeClass }
            fadeClass={fadeClass}
            fadeClassBtm={ fadeClassBtm }
            setFadeClassBtm={ setFadeClassBtm }
            launchVideo={ launchVideo }
            displayVideo={ displayVideo }
            handleRedirect={ handleRedirect }
            practiceLabel={ practiceLabel }
            realLabel={ realLabel }
            error={ error }
        />
    );
}

export default IntroContainer;
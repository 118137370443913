import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Sitemap(props){

    // Assemble the JSX for the pin image
    const getPinImg = (pin) => {

        var pinImg;
        var pinClass;
        if (pin.quantId > 0) {
            pinImg = props.pinMap['treatment'][pin.pinId];
        } else if (pin.pinOptionId > 0) {
            pinImg = props.pinMap['trap'][pin.pinId];
        } else {
            pinImg = props.pinMap['proofing'][pin.pinId];
        }

        pinClass = pinImg.split('.')[0];
        return <img src={ "/img/pins/green/" + pinImg} alt="Pin icon" className={"pin-icon " + pinClass} />;
    }

    const practiceLocations = [1,2,3,4,5];
    const realLocations = [10,11,12,13,14, 15, 16, 17, 18, 19, 20, 31,32,33,34,35,36,37,38,39,40,41];

    return (
        <div className={props.isPractice ? "siteplan-wrap" : "siteplan-wrap"}>
            <div className={props.isPractice ? "siteplan practice" : "siteplan real" }>

                { props.isPractice ? (
                    <Fragment>
                        { practiceLocations.map((locId) => 
                            <Fragment key={ 'pl-' + locId }>
                                {typeof props.existingPins[locId] != "undefined" && typeof props.existingPins[locId][0] != "undefined" &&
                                    <div className={"siteplan-pin location-" + locId}>
                                        <Link to={"/practice/location/" + locId}></Link>
                                        {getPinImg(props.existingPins[locId][0])}
                                    </div>
                                }
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        { realLocations.map((locId) => 
                            <Fragment key={'pl-' + locId}>
                                {typeof props.existingPins[locId] != "undefined" &&
                                    <div className={"siteplan-pin location-" + locId}>
                                        <Link to={"/practice/location/" + locId}></Link>
                                        {getPinImg(props.existingPins[locId][0])}
                                    </div>
                                }
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default Sitemap;
const Outside = [
    {
        locationId: 31,
        i: 80,
        a: -5,
        type: "link",
        lnk: "/assessment"
    },
    {
        locationId: 32,
        i: 109,
        a: -24.5,
        type: "info",
        text: "bins",
        img: "/img/zooms/bins.jpg"
    },
    {
        locationId: 33,
        i: 145,
        a: -19,
        type: "info",
        text: "Fence",
        img: "/img/zooms/fence-end.jpg"
    },
    {
        locationId: 34,
        i: -127,
        a: 44,
        type: "info",
        text: "Plant",
        img: "/img/zooms/weeds.jpg"
    },
    {
        locationId: 35,
        i: 148,
        a: -80,
        type: "info",
        text: "Trellis",
        img: "/img/zooms/trellis.jpg"
    },
    {
        locationId: 36,
        i: 85,
        a: -18,
        type: "info",
        text: "Gap under fence",
        img: "/img/zooms/door-corner.jpg"
    },
    {
        locationId: 37,
        i: 68,
        a: -28,
        type: "info",
        text: "Blue barrel",
        img: "/img/zooms/blue_barrel.jpg"
    },
    {
        locationId: 38,
        i: 49,
        a: -45,
        type: "info",
        text: "Red bucket",
        img: "/img/zooms/cleaning.jpg"
    },
    {
        locationId: 39,
        i: -32,
        a: 85,
        type: "info",
        text: "Yellow wheel",
        img: "/img/zooms/yellow-wheel.jpg"
    },
    {
        locationId: 40,
        i: -28,
        a: 44,
        type: "info",
        text: "Drain",
        img: "/img/zooms/drain.jpg"
    },
    {
        locationId: 41,
        i: -55,
        a: 20,
        type: "info",
        text: "Bushes",
        img: "/img/zooms/bushes.jpg"
    },
];

export default Outside;
// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Fetching from '../../utilities/fetching';
import CryptoJS from 'crypto-js';

import { useCookies } from 'react-cookie';
import Dashboard from '../../components/users/dashboard';

const DashboardContainer = (props) => {

    let encSecret = process.env.REACT_APP_ENC_KEY;
    const [authCookie] = useCookies(['bcpa-ta']);
    const [recent, setRecent] = useState(false)
    const [recentLoading, setRecentLoading] = useState(true)
    const [stats, setStats] = useState(false)
    const [statLoading, setStatLoading] = useState(true)

    const navigate = useNavigate();

    const loadRecent = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined") {
            // Not logged in so everything is cool
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url = process.env.REACT_APP_API_BASE + '/report/recent';
        fetch(url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setRecent(data.detail);
            }

            setRecentLoading(false);
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    const loadStats = () => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined") {
            // Not logged in so everything is cool
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        let url = process.env.REACT_APP_API_BASE + '/dashboard';
        fetch(url, {
            method: 'get',
            headers: {
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setStats(data.detail);
            }

            setStatLoading(false);
        })
        .catch(function (error) {
            console.log('Request failed', error);
        });

    }

    

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Dashboard :: TechAssure';
        loadRecent()
        loadStats()
    }, []);
    /* eslint-enable */

    return (
        <Dashboard 
            recent={recent}
            recentLoading={recentLoading}
            stats={stats}
            statLoading={statLoading}
        />
    );
}

export default DashboardContainer;
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HostspotQuestionContainer from '../../containers/tests/hostspot-question-container';
import Loader from '../common/loader';

import Logo from './../../assets/img/bpca/bpca-logo.png'
import Room from './room';
import Sitemap from './sitemap';
import Timer from './timer';

function Assessment(props){

    const [loadedImgs, setLoadedImgs] = useState([]);
    const [allLoaded, setAllLoaded] = useState(false);

    const trackLoaded = (img) => {
        var newImgs = {...loadedImgs};
        if (typeof newImgs[img] != "undefined") {
            newImgs[img] = true;
            setLoadedImgs(newImgs);
        }

        // Check whether everything has loaded 
        var allLoaded = true;
        for (var i in newImgs) {
            if (!newImgs[i]) {
                allLoaded = false;
                break;
            }
        }
        setAllLoaded(allLoaded);

    }

    useEffect(() => {
        
        var pinImgs = {};
        for (var i in props.pinMap) {
            for (var j in props.pinMap[i]) {
                pinImgs[props.pinMap[i][j]] = false;
            }
        }
    }, [props.pinMap])

    return (
        <Fragment>
            <section className="assessment">
                <div className="assessment-header">
                    <div className={ props.isSitemap ? 'logo-sxn lght left' : 'logo-sxn left'}>
                        <img src={Logo} alt="BPCA logo" />
                        <h2>TechAssure</h2>
                    </div>

                    <div className="right-header">
                        {!props.isPractice && props.remaining &&
                            <Timer remaining={ props.remaining } />
                        }
                        {!props.isSitemap ? (
                            <Link to={props.isPractice ? "/practice/sitemap" : "/assessment/sitemap"} className="test-btn sitemap-btn">Sitemap</Link>     
                        ) : (
                            <Link to={props.isPractice ? "/practice" : "/assessment"} className="test-btn camera">View room</Link>     
                        )}
                    </div>
               
                </div>

                { !props.isSitemap ? (
                    <Fragment>
                        { allLoaded ? (
                            <Room {...props} />
                        ) : (
                            <div id="roomView"><Loader mtop={true} /></div>
                        )}
                    </Fragment>
                ) : (
                    <Sitemap {...props} />
                )}

                {props.hotspotQuestion &&
                    <HostspotQuestionContainer { ...props } />
                }

                <div className="assessment-footer">
                    <button className="btn btn-submit" onClick={props.submitAssessment}>Submit assessment</button>
                </div>

                { props.completedLightbox && 
                    <div className="cover dark">
                        <div className="hover-box">
                            <h2>Assessment completed</h2>
                            <p>You have run out of time - your assessment results have been submitted and you can see them on your dashboard.</p>
                            <Link to="/intro" className="btn">Dashboard</Link>
                        </div>
                    </div>
                }

            </section>
            <div className="preloading-imgs">
                {props.pinMap &&
                    <Fragment>
                        {Object.keys(props.pinMap).map((pinKey, i) =>
                            <Fragment key={'hid-' + i}>
                                {Object.keys(props.pinMap[pinKey]).map((indKey, i) =>
                                    <img key={'ind-' + indKey} onLoad={() => trackLoaded(props.pinMap[pinKey][indKey]) } src={"/img/hotspots/" + props.pinMap[pinKey][indKey]} alt="Pin icon hidden" />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default Assessment
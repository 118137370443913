import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

// Assets
import Logo from './../../assets/img/bpca/bpca-logo.png';

function AdminNav(props){

    const {pathname} = useLocation()

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <header className="sidebar">
            <div className='logo-sxn'>
                <img src={Logo} alt="BPCA logo" />
                <h2>TechAssure</h2>
            </div>
            <nav className="admin-menu">
                <Link className="mobile-menu admin" to='#menu' onClick={(e) => toggleMenu(e)}>Menu</Link>
                <ul className={menuOpen ? "open" : ""}>
                    <li className={ pathname.indexOf("dashboard") > -1 ? "current" : "" }><Link to='/admin/manage/dashboard' id="dashboardLnk">Dashboard</Link></li>
                    {props.permissions.Users &&
                        <li className={pathname.indexOf("users") > -1 ? "current" : ""}><Link to='/admin/manage/users' id="usersLnk">Employee accounts</Link></li>
                    }
                    {props.permissions.Clients &&
                        <li className={pathname.indexOf("clients") > -1 ? "current" : ""}><Link to='/admin/manage/clients' id="clientsLnk">Companies</Link></li>
                    }
                </ul>
                <ul className={menuOpen ? "btm open" : "btm"}>
                    {props.permissions.Tests &&
                        <li className="btm"><Link to='/intro' id="assessmentLnk">TechAssure Assessment</Link></li>
                    }
                    <li className="btm"><Link to='/u/logout'>Logout</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default AdminNav
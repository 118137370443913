import React from 'react';
import Button from '../../common/forms/button';
import TextInput from '../../common/forms/text-input';
import { Navigate } from 'react-router-dom';
import ValidationError from '../../common/forms/validation-error';
import Error from '../../common/forms/error';
import Password from '../../common/forms/password';

function ChangeDetails(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    let emailLinkPopup = null

    return (
        <div className="usr-settings">
            <div className="usr-settings-head">
                <div className="shard-one">&nbsp;</div>
                <div className="shard-two">&nbsp;</div>
            </div>
            {emailLinkPopup}
            <section className='centred'>

                <h1>Edit my Details</h1>

                <Error error={props.error}/>
                
                <TextInput label='First Name' id='firstName' value={props.settings.firstName} handleChange={(e) => props.handleUpdate('firstName',e) }/>
                <TextInput label='Last Name' id='lastName' value={props.settings.lastName} handleChange={(e) => props.handleUpdate('lastName',e) }/>
                <TextInput label='Email' id='email' value={props.settings.email} handleChange={(e) => props.handleUpdate('email',e) }/>
                <ValidationError errors={props.valErrors} fieldName='email'/>
                <TextInput label='Phone' id='phone' value={props.settings.phone} handleChange={(e) => props.handleUpdate('phone',e) }/>

                <hr className='password-divider'/>
                <Password label='Password' id='password' value={props.settings.password} handleChange={(e) => props.handleUpdate('password',e) }/>
                <ValidationError errors={props.valErrors} fieldName='password'/>
                <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.saveUser}/>
            </section>
  
        </div>
    )
}

export default ChangeDetails
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import KitchenImg from './../../assets/img/samples/kitchen.jpeg';
import HomeKitchenImg from './../../assets/img/samples/instructions-kitchen.jpg';
import HotspotImg from './../../assets/img/bpca/hotspot-22.png';
import PinImg from './../../assets/img/bpca/empty-pin.png';
import DoorImg from './../../assets/img/bpca/door.png';
import InfoIcon from './../../assets/img/bpca/info-icon.png';
import Compass from './../../assets/img/bpca/compass.png';
import Label from './../../assets/img/bpca/info.svg';

function Instructions(props){

    return (
        <Fragment>
            <section className="assessment instructions">
                <div className="width-12">
                    {props.isPractice ? (
                        <h1>The Scenario: Problems in the home kitchen</h1>
                    ) : (
                        <h1>Competency assessment: Rodents</h1>
                    )}
                </div>
                <div className="width-3 left">
                    {!props.isPractice ? ( 
                        <img src={ KitchenImg } alt="Commercial kitchen" />
                    ) : (
                        <img src={ HomeKitchenImg } alt="Home kitchen" />
                    )}
                </div>
                <div className="width-9 left">
                    { props.isPractice ? ( 
                        <Fragment>
                            <p>Using what you&rsquo;ve learned in the video, spend as much time as you like in this practice room getting
                                used to moving around and using the controls. There is no time limit here and anything you do will
                                have no bearing on your assessment.</p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h4>Scenario</h4>
                                <p>This is a new contract for your organisation although the restaurant has had a pest control contract
                                    in place previously with another contractor. All bait stations from the previous contractors have
                                    been removed.</p>
                                <p>Two members of staff have separately reported a sighting of a pest within the kitchen area. The
                                    information about the sightings will be available to you within the assessment room.</p>

                                <h4>Objective of the assessment</h4>
                                <p>Your organisation has been asked by the restaurant to carry out a survey of the site with the aim of:</p>
                                <ul>
                                    <li>Identifying the rodent pest</li>
                                    <li>Finding the root cause</li>
                                    <li>Selecting safe and effective controls bring the issue under control</li>
                                    <li>Choosing any proofing options that you think are appropriate to prevent re-occurence.</li>
                                </ul>
                                <p>The area that you will be surveying will be the kitchen area and the area immediately outside the
                                    kitchen. You can move around in and out of both the inside and outside areas as often as you wish.</p>

                                <h4>How to complete the assessment</h4>
                                <p>Once you are ready to proceed click on the
                                    &quot;Get Started&quot; button to begin your assessment. You will have 30 minutes to complete the
                                    assessment. If you exit the platform before you have completed it, you will not be able to return and
                                    your assessment will be marked as a fail.</p>
                                <p>Once you have surveyed the site and identified the pest, you will be able to access a number of
                                    control options which you will place within the environment. You will be expected to use a control
                                    options that are both effective and safe.</p>
                                <p>If you choose to use a chemical control method, product labels will be available to help you decide,
                                    based on your findings:</p>
                                <ul>
                                    <li>The most appropriate application method</li>
                                    <li>The most effective active ingredient and formulation</li>
                                    <li>The correct dosage and spacing</li>
                                </ul>

                                <p>It is important to note that these product labels have been designed specifically for this simulation
                                    and will only carry the information relevant to this assessment.</p>
                                <p>In addition to the information contained within the product labels, you must ensure, where
                                    applicable, that you work in accordance with all relevant legislation including the CRRU Code of
                                    Practice.</p>
                                <p>When you are happy that you have completed the assessment click on the submit button in the
                                    bottom left hand corner of the screen.</p>

                                <h4>Results</h4>
                                <p>When you have submitted your assessment you will immediately receive your results. These will be
                                    shown as a pass or a fail. If you pass the assessment you will receive a BPCA Certificate of Achievement. If you
                                    have failed the assessment you will need to book a resit through <a href="training@bpca.co.uk" target="_blank" rel="noreferrer">training@bpca.co.uk</a>.</p>
                                <p>To find out more about how we calcultate test results, you can <a href="#grading" onClick={(e) => props.toggleLb(e) }>read our grading criteria</a>.</p>


                            { props.lb &&
                                <div className="cover">
                                    <div className="hover-box">
                                        <Link to='#close' onClick={(e) => props.toggleLb(e)} className="close-lnk">Close</Link>

                                        <h2>Grading</h2>
                                        <p>Grading for the assessment is based on the choice of proofing and treatment measures taken. For example:</p>
                                        <ul>
                                            <li>Placement of proofing or treatment measure</li>
                                            <li>Treatment delivery method</li>
                                            <li>Treatment type</li>
                                            <li>Location</li>
                                            <li>Application method</li>
                                            <li>Bait dose and spacing</li>
                                        </ul>
                                        <p>Selection choices are graded into three categories:</p>
                                        <ul>
                                            <li>Pass</li>
                                            <li>Minor fail</li>
                                            <li>Major fail</li>
                                        </ul>
                                        <p>A pass is given where the correct proofing or treatment measure is selected for the pest involved and the treatment is placed within the correct location, placed in a safe and effective position and applied within label conditions.</p>
                                        <p>A minor fail is allocated where a proofing or treatment measure is selected that does not completely meet the pass criteria but which still meets label conditions. Examples include:</p>
                                        <ul>
                                            <li>Insufficient product placed to be effective.</li>
                                            <li>Incorrect bait spacing (products placed too close or too far away from each other)</li>
                                        </ul>   
                                        <p><strong><em>Learners are allowed no more than TWO minor fails during their assessment. Three or more minor fails is equal to ONE major fail and will return an overall fail grade for the assessment.</em></strong></p> 
                                        <ul>
                                            <li>Incorrect or excessive product used</li>
                                            <li>Product or proofing placed incorrectly</li>
                                        </ul>
                                        <p><strong><em>Any major fail will automatically return an overall fail grade for the assessment.</em></strong></p>
                                        <p>Upon completion of the assessment you will be informed of the result of your assessment on screen. This notification will include, where applicable, the number of minor and / or major fails you have accrued.</p>
                                        <p>Some additional information about the nature of the fails can be obtained through emailing <a href="mailto:training@bpca.org.uk" target="_blank" rel="noreferrer">training@bpca.org.uk</a>. However, this feedback will be limited to an overview of the type of error made, ie too little product, treatments placed too close together. We are unable to provide feedback on what the correct action should have been.</p>
                                    </div>            
                                </div>            
                            }
                        </Fragment>
                    )}
                    
                    <h4>Instructions</h4>
                    <p>As explained in the introductory video, here are some of the icons that you will see and can interact with.</p>
                    
                    <div className="icon-instruction">
                        <img src={ HotspotImg } alt="Hotspot icon" />
                        <p><strong>Areas for inspection:</strong> Whilst exploring the room you will see a variety of &quot;Areas for inspection&quot;. Click on them to zoom in onto the area an apply treatments or proofing.</p>
                    </div>

                    <div className="icon-instruction">
                        <img src={PinImg} alt="Pin icon" />
                        <p><strong>Treatment applied:</strong> You can drop the treatment and proofing option pins in the different areas of the room. You can position the pin by dragging an dropping them. Press and hold on an already dropped pin to remove it.</p>
                    </div>

                    <div className="icon-instruction">
                        <img src={Label} alt="Product label icon" />
                        <p><strong>More info:</strong> Click on the &quot;more info&quot; icon to find out more about a particular treatment and view the product label.</p>
                    </div>

                    <div className="icon-instruction">
                        <img src={Compass} alt="Site plan icon" />
                        <p><strong>Site plan:</strong> The site plan allows you to see the whole site and where you have placed different treatments. Use this to make sure that the different treatments will work together effectively.</p>
                    </div>

                    { !props.isPractice &&
                        <Fragment>
                            <div className="icon-instruction">
                                <img src={InfoIcon} alt="Log book icon" />
                                <p><strong>Log book:</strong> View the log book to see what treatments have been applied previously and understand where pests have been spotted.</p>
                            </div>

                            <div className="icon-instruction">
                                <img src={DoorImg} alt="Door icon" />
                                <p><strong>Door:</strong> Use this icon to go through the door.</p>
                            </div>
                        </Fragment>
                    }

                    <Link to="#start" className="btn" onClick={(e) => props.handleStart(e) }>Get started</Link>
                </div>
            </section>
        </Fragment>
    )
}

export default Instructions
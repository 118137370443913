import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import moment from "moment";

import CompanyBig from './../../../assets/img/bpca/company-big.svg'
import EmployeeBig from './../../../assets/img/bpca/employee-big.svg'
import Assessment from './../../../assets/img/bpca/assessment.svg'
import Avatar from './../../../assets/img/bpca/avatar.png'
import Loader from '../../common/loader';

function Dashboard(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    const capitaliseFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    return (
        <div className='width-12'>
            <h1>Dashboard</h1>

            <section className="highlights">
            
                <div className="thirds companies">
                    <div className="hex-icon">
                        <img src={ CompanyBig } alt="Company icon" />
                    </div>
                    <h6>{ props.statLoading ? "-" : props.stats.totalClients }</h6>
                    <p>Total companies</p>

                    <Link to="/admin/manage/clients/create" className="btn curved">Add new</Link>
                </div>

                <div className="thirds users">
                    <div className="hex-icon">
                        <img src={EmployeeBig} alt="Employees icon" />
                    </div>
                    <h6>{props.statLoading ? "-" : props.stats.totalUsers}</h6>
                    <p>Total employees</p>

                    <Link to="/admin/manage/users/create" className="btn curved">Add new</Link>
                </div>

                <div className="thirds assessments">
                    <div className="hex-icon">
                        <img src={Assessment} alt="Assessments icon" />
                    </div>

                    <h6>{ props.statLoading ? "-" : props.stats.totalTests }</h6>
                    <p>Total assessments</p>

                    <p className="stat"><span>{props.statLoading ? "-" : props.stats.totalPass}</span> Passed</p>
                    <p className="stat"><span>{props.statLoading ? "-" : props.stats.totalFail}</span> Failed</p>
                </div>

            </section>

            <div>
                <Link to="/admin/manage/users" className="right sml">View more</Link>
                <h2>Latest assessments</h2>

                { props.recentLoading ? (
                    <Loader />
                ) : (

                    <div className='pseudo-table'>

                        { props.recent.map( (report, i) => 
                            <div key={"rep-" + i} className="pseudo-row">
                                <img src={Avatar} alt="avatar" />

                                <div className="cell">
                                    <p><strong>{ report.User.FirstName } { report.User.LastName }</strong></p>
                                    <p>{ report.User.EmailAddress }</p>
                                </div>

                                <div className="cell sml-hide">
                                    <ul>
                                        <li>{ report.User.Client.ClientName }</li>
                                    </ul>
                                </div>

                                <div className="cell med-hide">
                                    <ul>
                                        <li>{ moment.unix(report.SubmissionTime).format("HH:mm, DD/MM/YYYY") }</li>
                                    </ul>
                                </div>

                                <div className={"cell " + report.OverallResult}>
                                    <span className={"right rslt-pod big " + report.OverallResult}>{ capitaliseFirstLetter(report.OverallResult) }</span>
                                </div>
                            </div>
                        )}

                    </div>

                )}
            </div>

        </div>
    )
}

export default Dashboard;
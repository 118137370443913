import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import * as PANOLENS from "panolens";
import * as THREE from "three";


function Room (props){

    const [log, setLog] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [pan, setPan] = useState(false);
    const [bg, setBg] = useState(false);

    // viewer defaults to looking directly at door
    
    // I think we're working in a 3D cooridante space and the image is based on the interior of a sphere.
    // I'm sure the browser is positioned at (0,0,0).
    // It is therefore very difficult to place an infospot in (x, y ,z ) and maintain size without guesswork.
    // This uses spherical cooridates as we try to place the coordinates on the surface area of the sphere.
    // We know:
    //     - the radius of the image sphere
    //     - determine the angle of vertical rotation for the infospot
    //     - determine the angle of horizontal rotation for the infospot
    // Calculate x,y and z from these values and plot
    // This logic is taken from the infospots example on the panoma js site
    // There must be some offset as the values in the trig do not quite work or something is unaccounted for.

    //Use these values as a guide to plot additional points

    const navigate = useNavigate();

    // Load up the panorama viewer
    const spotSize = 450;
    const loadViewer = () => {

        // Track the BG image in state so we can note the change when we go outside / inside 
        setBg(props.bgImg);

        // Set the core panorama image up
        let panoramicImage = props.bgImg;
        var panorama = new PANOLENS.ImagePanorama(panoramicImage);
        const { radius } = panorama;

        var posn;
        var spots = {};
       
        // Loop through the hotspot positions to display them
        props.configFile.forEach( config => {

            // Set the position of the icon
            posn = calculateCoordinates(config, radius);
            spots["loc-" + config.locationId] = false;

            // Figure out what the icon will be
            var icon;
            if (config.type === 'info') {
                // A treatment icon (either hotspot icon or applied treatment icon)
                icon = getIconImg(config.locationId);
            } else if (config.type === 'info-icon') {
                // Log book info icon
                icon = "/img/hotspots/info-icon.png";                
            } else {
                // Door icon for going inside / outside
                icon = "/img/hotspots/door.png";               
            }

            // Load the infospot image it into Three / WebGL as an image
            // Prevents black spots or missing icons if they do not load properly
            var textureLoader = new THREE.ImageLoader();

            // Set up the positions and append the infospot onto the panorama
            var x = posn.x, y = posn.y, z = posn.z;
            textureLoader.load(icon, function () {

                // Define the infospot details
                // Do this inside the callback to ensure that the texture loader has worked before trying to display the infospot
                spots["loc-" + config.locationId] = new PANOLENS.Infospot(spotSize, icon, true);
                spots["loc-" + config.locationId].name = "loc-" + config.locationId;
                spots["loc-" + config.locationId].position.set(x, y, z);

                // Vary the click responses according to infospot type
                if (config.type === 'info-icon') {
                    // Info icon click should display the log book
                    spots["loc-" + config.locationId].addEventListener('click', () => toggleLog(config))
                } else if (config.type === 'info') {
                    // Ordinary treatment icon click should display the hotspot question
                    spots["loc-" + config.locationId].addEventListener('click', () => clickListen(config))
                } else {
                    // Door icon click should navigate to the next room
                    spots["loc-" + config.locationId].addEventListener('click', () => {
                        const myNode = document.getElementById("roomView");
                        myNode.innerHTML = '';
                        navigate(config.lnk)
                    });
                }

                // Add the spot onto the panorama
                panorama.add(spots["loc-" + config.locationId]);

            });

        });
        
        // Configure the viewer itself
        const viewer = new PANOLENS.Viewer({
            container: document.querySelector("#roomView"),
            cameraFov: 80,
            reverseDragging: false,
            enableReticle: false,
            dwellTime: 1500,
            autoReticleSelect: true,
            output: "overlay",
            autoHideInfospot: false,
            controlBar: false,
            horizontalView: false,
        });
        viewer.add(panorama);

        // Track the panorama details in state so it can be updated later
        setPan(panorama);
        setLoaded(true);

    }

    // Figure out the icon image 
    const getIconImg = (locId) => {

        // Work out what the image should be for this location
        var iconImg = "/img/hotspots/hotspot-22.png";
        if (typeof props.existingPins[locId] != "undefined" && typeof props.existingPins[locId][0] != "undefined" ) {
            var pinId = props.existingPins[locId][0].pinId;
            if (props.existingPins[locId][0].quantId > 0) {
                // Treatment icon required
                iconImg = props.pinMap['treatment'][pinId];
            } else if (props.existingPins[locId][0].pinOptionId > 0) {
                // Trap option
                iconImg = props.pinMap['trap'][pinId];
            } else {
                // Proofing option
                iconImg = props.pinMap['proofing'][pinId];
            }
            iconImg = "/img/hotspots/" + iconImg;
        }

        return iconImg;
    }


    // Update an image hotspot after a pin has been added to a particular location
    const updateHotspots = (locId) => {

        // Extract the actual location ID from the location data
        locId = locId.split('-')[0];

        // Work out what the image should be for this location
        var iconImg = getIconImg(locId);

        // Loop through the existing children
        var reqKey = false;
        for (var i in pan.children) {
            // If the name of the child matches the thing we are trying to replace, then get it's key
            if (pan.children[i].name === "loc-" + locId) {
                reqKey = i;
            }
        }

        // Delete the child infospot that exists (so it can be replaced)
        var textureLoader = new THREE.ImageLoader();
        var x, y, z = 0;
        if (typeof pan.children[reqKey] !== 'undefined') {
            x = pan.children[reqKey].position.x;
            y = pan.children[reqKey].position.y;
            z = pan.children[reqKey].position.z;
            delete pan.children.splice(reqKey, 1)
        }

        // Figure out the config file that should be triggered next time the hotspot is clicked
        var configToTrigger = false;
        for (var j in props.configFile) {
            if (props.configFile[j].locationId === parseInt(locId)) {
                configToTrigger = props.configFile[j];
            }
        }

        if (configToTrigger) {
            // Update the image to the required image
            var icon = iconImg;
            textureLoader.load(icon, function () {
                var spot = new PANOLENS.Infospot(spotSize, icon, true);
                spot.name = "loc-" + locId;
                spot.addEventListener('click', () => clickListen(configToTrigger))
                spot.position.set(x, y, z);
                pan.add(spot);
                spot.show();
            });
        }
    }
    
    // Calculate the x, y, z coordinates for an infospot
    const calculateCoordinates = (infoPoints, radius) => {

        const phi = Math.PI;

        var posn = {};
        posn.x = radius * Math.sin(infoPoints.i * (phi / 180)) * Math.cos(infoPoints.a * (phi / 180));
        posn.y = radius * Math.sin(infoPoints.i * (phi / 180)) * Math.sin(infoPoints.a * (phi / 180));
        posn.z = radius * Math.cos(infoPoints.i * (phi / 180));

        return posn;

    }

    // Handle the click on a hotspot (the treatment areas)
    const clickListen = (config) => {
        if (typeof props.displayHotspotQuestion != "undefined") {
            props.displayHotspotQuestion(config);
        }
    }

    // Toggle the log book lightbox display upon click of the hotspot that displays the info icon
    const toggleLog = (config) => {
        if (typeof config.img == "undefined") config.preventDefault();
        if (log) {
            setLog(false);
        } else {
            setLog(config.img);
        }
    }

    /*eslint-disable */
    // Load up the initial viewer once all viewer data has been loaded
    useEffect(() => {
        // trackPinLocations(props.existingPins);
        if ((!loaded || props.bgImg !== bg) && props.bgImg && props.configFile && props.pinMap && props.existingPins) {
            loadViewer();
        }
    }, [props.bgImg, props.configFile, props.pinMap, props.existingPins, props.hotspotQuestion])

    // Whenever a pin is dropped, update the hotspots
    useEffect(() => {
        // trackPinLocations(props.existingPins);
        if (loaded) {
            updateHotspots(props.lastPinDropLoc);
        }
    }, [props.lastPinDropLoc])
    /*eslint-enable */

    // Render the room view - as part of it include some hidden versions of the icons to preload them for the viewer
    return (
        <Fragment>
            <div id="roomView"></div> 
            { log &&
                <div className="cover">
                    <div className="hover-box log-box">
                        <Link to="#close" className="close-lnk" onClick={(e) => toggleLog(e)}>Close</Link>
                        <h2>Log book</h2>
                        <img src={ log } alt="Log book" />
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default Room

// Core react and plugins
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';

import Password from '../../common/forms/password';
import Button from '../../common/forms/button';

// Components
import Error from './../../common/forms/error';
import PwMeter from './pw-meter';

// Component class itself
class SetPw extends Component {

    componentDidMount() {
        document.title = 'Set your password :: TechAssure';
    }

    render() {

        if (this.props.rdr) {
            return <Navigate to={this.props.rdr} />
        }

        // if (this.props.loading) {
        //     return <Loader />;
        // }

        return (

            <div className={ this.props.termsRequired ? "mfa-setup" : "" }>

                <h2>Set your password</h2>

                <Error error={ this.props.error } />

                <form id="pwForm">

                    <PwMeter { ...this.props } /> 
                    <Password label={'Password'} id={'password'} handleChange={ (e) => this.props.handleUpdate("password", e)} />
                    <Password label={'Confirm Password'} id={'confirm'} handleChange={ (e) => this.props.handleUpdate("confirm", e)} />

                    <Button handleClick={ (e) => this.props.submitFunc(e) } btnTxt={ this.props.btnTxt }/>

                </form>

                <p className="sml">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
            </div>

        );

    }
}

export default SetPw;
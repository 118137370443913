import React, { Fragment } from 'react';

import Logo from './../../assets/img/bpca/bpca-logo.png';

import { useDrop } from 'react-dnd'
import { Pin } from './pins/pin.js'
import { ItemTypes } from './pins/itemTypes.js'

function HotspotQuestion(props){

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.BOX,
            drop(item, monitor) {
                const clientOffset = monitor.getSourceClientOffset();
                props.updatePin(item.id, clientOffset.x, clientOffset.y);
                return undefined
            },
        }),
        [props.updatePin],
    )

    return (
        <div className="hotspot-cover">
            <section className="hotspot-question">
                <button className="close-btn" onClick={(e) => props.closeHotspotQuestion(e)}>Close</button>

                    <div className='logo-sxn'>
                        <img src={Logo} alt="BPCA logo" />
                        <h2>TechAssure</h2>
                    </div>
                    
                    <h5>Proofing</h5>
                    <p>Select any proofing options that you would place in this location, then position them on the image in an appropriate position.</p>

                    <button className={props.openStatuses.proofing ? "select-opener open" : "select-opener"} onClick={ () => props.toggleSelect("proofing") }>Select a proofing option</button>
                    { props.openStatuses.proofing && props.questionData && typeof props.questionData.proofing != "undefined" &&
                        <ul className="pseudo-select">
                            { props.questionData.proofing.map( (item, i) => 
                                <li key={ "proof-" + i }>
                                    <span className={ props.proofings.indexOf(item.id) > -1 ? "option-select selectable selected" : "option-select selectable" } onClick={ (e) => props.selectOption(e, 'proofing', item.id) }>
                                        { item.name }

                                        { typeof props.pins["proofing-" + item.id] != "undefined" && !props.pins["proofing-" + item.id].moved &&
                                            <Pin
                                                key={"proofpin-" + item.id }
                                                id={props.pins["proofing-" + item.id].id }
                                                top={props.pins["proofing-" + item.id].top}
                                                left={props.pins["proofing-" + item.id].left}
                                                img={props.pins["proofing-" + item.id].pinImg}
                                                title={props.pins["proofing-" + item.id].title}
                                            >
                                                {props.pins["proofing-" + item.id].title}
                                            </Pin>
                                        }
                                    </span>
                                </li>
                            )}
                        </ul>
                    }

                    <h5>Traps</h5>
                    <p>Select any traps that you would position in this location, then position them on the image in an appropriate position.</p>

                    <button className={props.openStatuses.trapType ? "select-opener open" : "select-opener"} onClick={() => props.toggleSelect("trapType")}>Select a trap option</button>
                    {props.openStatuses.trapType && props.questionData && typeof props.questionData.traps != "undefined" &&
                        <ul className="pseudo-select">
                            {props.questionData.traps.map((item, i) =>

                                <li key={"trap-" + i}>
                                    <button className={props.openTraps.indexOf(item.id) > -1 ? "option-select opener open" : "option-select opener"} onClick={(e) => props.toggleOpenTraps(e, item.id) }>
                                        <h6>{item.name}</h6>
                                        <p>{item.detail}</p>
                                    </button>

                                    {props.openTraps.indexOf(item.id) > -1 && typeof item.options.opts != "undefined" && item.options.opts.length > 0 &&
                                        <ul className="sub-options">
                                            {item.options.opts.map((opt, j) =>
                                                <li key={"subopt-" + j}>
                                                    <span className={props.traps.indexOf(opt.id) > -1 ? "option-select selectable selected" : "option-select selectable"} onClick={(e) => props.selectOption(e, 'trap', opt.id)}>
                                                        <h6>{opt.name}</h6>
                                                        {typeof props.pins["traps-" + opt.id] != "undefined" && !props.pins["traps-" + opt.id].moved &&
                                                            <Pin
                                                                key={"trappin-" + opt.id}
                                                                id={props.pins["traps-" + opt.id].id}
                                                                top={props.pins["traps-" + opt.id].top}
                                                                left={props.pins["traps-" + opt.id].left}
                                                                img={props.pins["traps-" + opt.id].pinImg}
                                                                title={props.pins["traps-" + opt.id].title}
                                                            >
                                                                {props.pins["traps-" + opt.id].title}
                                                            </Pin>
                                                        }
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </li>
                            )}
                        </ul>
                    }

                    <h5>Rodenticide</h5>
                    <p>Select any rodenticide that you would position in this location, then place it on the image in an appropriate position.</p>

                    <button className={props.openStatuses.treatmentSecured ? "select-opener open" : "select-opener"} onClick={() => props.toggleSelect("treatmentSecured")}>Select a treatment delivery method</button>
                    {props.openStatuses.treatmentSecured &&
                        <ul className="pseudo-select">
                            <li className={ props.treatType === "covered" ? "pseudo-radio selected" : "pseudo-radio" }>
                                <button className="option-select" onClick={ () => props.setTreatType("covered") }>
                                    <h6>Tamper resistant bait station with rodenticide</h6>
                                </button>
                            </li>
                            <li className={props.treatType === "uncovered" ? "pseudo-radio selected" : "pseudo-radio"}>
                                <button className="option-select" onClick={ () => props.setTreatType("uncovered") }>
                                    <h6>Not covered or protected</h6>
                                </button>
                            </li>
                        </ul>
                    }

                    { props.treatType !== "" }
                    <button className={props.openStatuses.treatmentType ? "select-opener open" : "select-opener"} onClick={() => props.toggleSelect("treatmentType")}>Select a treatment type</button>
                    {props.openStatuses.treatmentType && props.questionData && typeof props.questionData.treatments != "undefined" &&
                        <ul className="pseudo-select">
                            {props.questionData.treatments.map((item, i) =>
                                <Fragment key={"treat-" + i}>
                                    { ((props.treatType === "covered" && item.isCovered) || (props.treatType === "uncovered" && !item.isCovered)) &&
                                        <li key={"treatr-" + i}>
                                            <button className={props.openTreatments.indexOf(item.id) > -1 ? "option-select opener open" : "option-select opener"} onClick={ (e) => props.toggleTreatmentSection(e, item.id) }>
                                                <h6>{item.name}</h6>
                                                <p>{item.detail}</p>
                                            </button>
                                            {props.openTreatments.indexOf(item.id) > -1 && typeof item.options.opts != "undefined" && item.options.opts.length > 0 &&
                                                <ul className="sub-options">
                                                    { item.options.opts.map ( (opt, j) => 
                                                        <li key={"subopt-" + j}>
                                                            <button className="info" onClick={ (e) => props.launchLabel(e, opt.id, opt.name, opt.imgLabel) }>More info</button>
                                                            <button className={props.openTreatmentOpts.indexOf(opt.id) > -1 ? "option-select with-info opener open" : "option-select with-info opener"} onClick={(e) => props.toggleTreatmentOpt(e, opt.id)}>
                                                                <h6>{opt.name}</h6>
                                                            </button>
                                                            { props.openTreatmentOpts.indexOf(opt.id) > -1 && typeof opt.quantities != "undefined" && opt.quantities.length > 0 && 
                                                                <ul className="sub-options quantity">
                                                                    { opt.quantities.map ( (quan, k) => 
                                                                        <li key={"quanopt-" + k}>
                                                                            <span className={ props.treatments.indexOf(quan.id) > -1 ? "option-select selectable selected" : "option-select selectable"} onClick={(e) => props.selectOption(e, 'treatment', quan.id)}>
                                                                                <h6>{quan.value}</h6>
                                                                                {typeof props.pins["treatments-" + quan.id] != "undefined" && !props.pins["treatments-" + quan.id].moved &&
                                                                                    <Pin
                                                                                        key={"treatmentin-" + quan.id}
                                                                                        id={props.pins["treatments-" + quan.id].id}
                                                                                        top={props.pins["treatments-" + quan.id].top}
                                                                                        left={props.pins["treatments-" + quan.id].left}
                                                                                        img={props.pins["treatments-" + quan.id].pinImg }
                                                                                        title={props.pins["treatments-" + quan.id].title}
                                                                                    >
                                                                                        {props.pins["treatments-" + quan.id].title}
                                                                                    </Pin>
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            }
                                                        </li>
                                                    )}
                                                </ul>
                                            }
                                        </li>
                                    }
                                </Fragment>
                            )}
                        </ul>
                    }

                </section>

                <div className="hotspot-img" ref={drop}>
                    <button className="close-btn" onClick={(e) => props.closeHotspotQuestion(e)}>Close</button>
                    <div className='drop-img' id="dropImg" onClick={ (e) => props.cancelRemoval(e) } style={{ backgroundImage: 'url("' + props.hotspotQuestion.img + '")' }}></div>
                    
                    {Object.keys(props.pins).map((pin, i) =>
                        <Fragment key={ "pp-" + i }>
                            { props.pins[pin].moved &&
                                <Pin
                                    key={"inimg-" + props.pins[pin].id}
                                    id={props.pins[pin].id}
                                    top={props.pins[pin].top}
                                    left={props.pins[pin].left}
                                    img={props.pins[pin].pinImg }
                                    title={props.pins[pin].title}
                                    isBig={ true }
                                    removePin={props.removePin }
                                    removeLb={ props.removeLb }
                                    cancelRemoval={ props.cancelRemoval }
                                    completeRemoval={ props.completeRemoval }
                                >
                                    {props.pins[pin].title}
                                </Pin>
                            }
                        </Fragment>
                    )}
                </div>

                { props.label && props.label.imgLabel !== "" && 
                    <div className="label-cover">
                        <div className="label">
                            <button className="close-btn dark" onClick={(e) => props.hideLabel(e)}>Close</button>
                            <h4>Product label: { props.label.name }</h4>
                            <img src={ "/img/labels/" + props.label.imgLabel } alt={"Product label: " + props.label.name} />
                        </div>
                    </div>
                }

        </div>
    )
}

export default HotspotQuestion
const HomeConfig = [
    {
        locationId: 1,
        i: 73,
        a: -48,
        type: "info",
        text: "Skirting gap",
        img: "/img/zooms/wooden_corner.jpg"
    },
    {
        locationId: 2,
        i: -45,
        a: 89,
        type: "info",
        text: "Washing machine",
        img: "/img/zooms/new_broom.jpg"
    },
    {
        locationId: 3,
        i: 103,
        a: -63,
        type: "info",
        text: "Oven",
        img: "/img/zooms/oven.jpg"
    },
    {
        locationId: 4,
        i: -75,
        a: 62,
        type: "info",
        text: "Bin",
        img: "/img/zooms/near_dustbin.jpg"
    },
    {
        locationId: 5,
        i: -110,
        a: 52,
        type: "info",
        text: "Fridge",
        img: "/img/zooms/below_fridge.jpg"
    },
];

export default HomeConfig;
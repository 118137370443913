import React, { Fragment } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Error from '../common/forms/error';
import moment from "moment";
import Loader from '../common/loader';

function UserList(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }


    const getReportLightboxContent = (userReports) => {
        let lightboxViewContent = null;

        if(userReports.length > 0){
            lightboxViewContent = <Fragment>
                <ul className='report-links'>
                    {props.userReports.map( (report,i) => {
                        if (report.SubmissionTime !== 0) {
                            if (report.TestType === "practice") {
                                return <li key={'rep-' + i}><Link to={'/admin/reports/' + report.ID} >Practice assessment report submitted on {moment(report.submissionDate).format("DD/MM/YYYY")}</Link></li>
                            } else {
                                return <li key={'rep-' + i}><Link to = {'/admin/reports/'+report.ID} >Assessment report submitted on { moment(report.submissionDate).format("DD/MM/YYYY") }</Link></li>
                            }
                        } else {
                            if (report.StartTime !== 0) {
                                var practiceStr = (report.TestType === "practice") ? "practice " : "";
                                return <li className='faded' key={'rep-' + i}>Unfinished {practiceStr}assessment started on {moment(report.startDate).format("DD/MM/YYYY")}</li>
                            } else {
                                var assessStr = (report.TestType === "practice") ? "Practice assessment " : "Assessment ";
                                return <li className='faded' key={'rep-' + i}>{assessStr}not yet started</li>
                            }
                        }
                    } ) }
                </ul>
                <button className='sml-btn' onClick={(e) => props.resetUsr(e, userReports[0].UserID) }>Reset</button>
            </Fragment>
        } else {
            lightboxViewContent = <p className="faded centred">This user has not yet started an assessment.</p>;
        }
        return lightboxViewContent
    }

    const getPasswordResetLightboxContent = (passwordResetUrl) => {
        let lightboxViewContent = <Fragment>
            <p>You can send the link below to this user so they can reset their password for their account.</p>
            <p className="lnk-wrap">{passwordResetUrl}</p>
        </Fragment>
        
        return lightboxViewContent
    }
    
    let reportListPopup = null
    let lightboxViewTitle = '';
    let lightboxViewContent = null;

    if(props.lightboxView === 'report'){
        lightboxViewTitle = 'User Assessment Reports'
        lightboxViewContent = getReportLightboxContent(props.userReports);
    } else if(props.lightboxView === 'resetPassword'){
        lightboxViewTitle = 'User password reset'
        lightboxViewContent =   getPasswordResetLightboxContent(props.passwordResetUrl)
    }
    

    if(props.lightBoxOpen){
        reportListPopup = <div className="cover">
            <div className="hover-box">
                <Link to='' onClick={(e) => props.closeLightbox(e)} className="close-lnk">Close</Link>
                <h2>{lightboxViewTitle}</h2>
                { props.lbError && 
                    <div className={ props.lbError.class }>
                        <p>{ props.lbError.msg }</p>
                    </div>
                }
                {lightboxViewContent}
            </div>
        </div>
    }
    
    return (
        <div className = 'width-12'>
            {/*props.userPermissions.Users && <Link to='/admin/manage/users/upload' className="btn minor faded">Upload user CSV</Link>*/}
            {props.userPermissions.Users && <Link to='/admin/manage/users/create' className="btn minor">Create employee</Link>}
            <h1>Manage employees</h1>

            <div className="filter">
                <h4>Filter employees</h4>

                <div className="input text">
                    <label htmlFor='searchInput'>Employee name</label>
                    <input type="text" id="searchInput" onChange={(e) => props.setSearchEntry(e.target.value)} />
                </div>

                <div className="input select">
                    <label htmlFor='clientInput'>Company</label>
                    <select id="clientInput" onChange={(e) => props.setClient(e.target.value)}>
                        <option value="0"></option>
                        {props.clients.map((client, i) =>
                            <option value={client.id} key={"copt-" + i}>{client.clientName}</option>
                        )}
                    </select>
                </div>

                <button onClick={() => props.loadUsers("search")}>Filter</button>
            </div>

            <div>
                {reportListPopup}

                <Error error={props.error}/>

                <div className="table-wrap" onScroll={props.handleScroll}>
                    <table>
                        <thead>
                            <tr>
                                <th>First name</th>
                                <th>Last name</th>
                                <th className='med-hide'>Email</th>
                                <th className='sml-hide'>Status</th>
                                <th className='big-hide'>Created</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            { typeof props.users != "undefined" && props.users.map( (user,i) => {
                                return (
                                    <tr key={'tr-'+i}>
                                        <td>{user.FirstName}</td>
                                        <td>{user.LastName}</td>
                                        <td className='med-hide'>{user.Email}</td>
                                        <td className='sml-hide'>
                                            { user.TestStatus === 'Pass' && 
                                                <span className="rslt-pod pass">Passed</span>
                                            }
                                            {user.TestStatus === 'Fail' &&
                                                <span className="rslt-pod fail">Failed</span>
                                            }
                                            {user.TestStatus !== 'Pass' && user.TestStatus !== 'Fail' &&
                                                <span className="rslt-pod">{ user.TestStatus }</span>
                                            }
                                        </td>
                                        <td className='big-hide'>{ moment(user.Created).format("DD/MM/YYYY") }</td>
                                        <td>
                                            <Link onClick={(e) => props.getUserReports(user.Ref,e)} to='' className="action-btn">Reports</Link>
                                            {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.resetUsrPw(user.Ref, e)} className="action-btn big-hide">Pwd</Link>: null }
                                            {!user.hideLink && props.userPermissions.Users ? <Link to={'/admin/manage/users/'+user.Ref} className="action-btn sml-hide">Edit</Link> : null }
                                            {!user.hideLink && props.userPermissions.Users ? <Link to='' onClick={(e) => props.deleteUser(user.Email, e)} className="action-btn red sml-hide">Delete</Link>: null }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {props.loading &&
                        <Loader />
                    }
                </div>
                    
            </div>

        </div>
    )
}

export default UserList
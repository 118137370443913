import React, {Fragment} from 'react';

import moment from 'moment';
import Loader from '../../common/loader';

import NewBroom from '../../../assets/img/zoom-annotate/new_broom.jpg';
import BelowCardCups from '../../../assets/img/zoom-annotate/below_card_cups.jpg';
import BelowDoor from '../../../assets/img/zoom-annotate/below_door.jpg';
import BelowFreezer from '../../../assets/img/zoom-annotate/below_freezer.jpg';
import BelowFridge from '../../../assets/img/zoom-annotate/below_fridge.jpg';
import BelowTable from '../../../assets/img/zoom-annotate/below_table.jpg';
import BelowUtensils from '../../../assets/img/zoom-annotate/below_utensils.jpg';
import BigFreezer from '../../../assets/img/zoom-annotate/big_freezer.jpg';
import Bins from '../../../assets/img/zoom-annotate/bins.jpg';
import BlueBarrel from '../../../assets/img/zoom-annotate/blue_barrel.jpg';
import Bushes from '../../../assets/img/zoom-annotate/bushes.jpg';
import Cleaning from '../../../assets/img/zoom-annotate/cleaning.jpg';
import Corner from '../../../assets/img/zoom-annotate/corner.jpg';
import DoorCorner from '../../../assets/img/zoom-annotate/door-corner.jpg';
import DownToOven from '../../../assets/img/zoom-annotate/downToOven.jpg';
import Drain from '../../../assets/img/zoom-annotate/drain.jpg';
import Dustbin from '../../../assets/img/zoom-annotate/dustbin.jpg';
import FenceEnd from '../../../assets/img/zoom-annotate/fence-end.jpg';
import NearDustbin from '../../../assets/img/zoom-annotate/near_dustbin.jpg';
import NearSoap from '../../../assets/img/zoom-annotate/near_soap.jpg';
import Oven from '../../../assets/img/zoom-annotate/oven.jpg';
import Trellis from '../../../assets/img/zoom-annotate/trellis.jpg';
import Weeds from '../../../assets/img/zoom-annotate/weeds.jpg';
import WoodenCorner from '../../../assets/img/zoom-annotate/wooden_corner.jpg';
import YellowWheel from '../../../assets/img/zoom-annotate/yellow-wheel.jpg';

function Report(props){

    const imgMap = {
        "/img/zooms/new_broom.jpg": NewBroom,
        "/img/zooms/below_card_cups.jpg": BelowCardCups,
        "/img/zooms/below_door.jpg": BelowDoor,
        "/img/zooms/below_freezer.jpg": BelowFreezer,
        "/img/zooms/below_fridge.jpg": BelowFridge,
        "/img/zooms/below_table.jpg": BelowTable,
        "/img/zooms/below_utensils.jpg": BelowUtensils,
        "/img/zooms/big_freezer.jpg": BigFreezer,
        "/img/zooms/bins.jpg": Bins,
        "/img/zooms/blue_barrel.jpg": BlueBarrel,
        "/img/zooms/bushes.jpg": Bushes,
        "/img/zooms/cleaning.jpg": Cleaning,
        "/img/zooms/corner.jpg": Corner,
        "/img/zooms/door-corner.jpg": DoorCorner,
        "/img/zooms/downToOven.jpg": DownToOven,
        "/img/zooms/drain.jpg": Drain,
        "/img/zooms/dustbin.jpg": Dustbin,
        "/img/zooms/fence-end.jpg": FenceEnd,
        "/img/zooms/near_dustbin.jpg": NearDustbin,
        "/img/zooms/near_soap.jpg": NearSoap,
        "/img/zooms/oven.jpg": Oven,
        "/img/zooms/trellis.jpg": Trellis,
        "/img/zooms/weeds.jpg": Weeds,
        "/img/zooms/wooden_corner.jpg": WoodenCorner,
        "/img/zooms/yellow-wheel.jpg": YellowWheel
    }

    const capitaliseFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    return (
        <div className="admin-container">
            <div className="content core-admin">
                <section className="width-12 report-content">
                    <h1>Assessment Report</h1>

                    { props.isLoading ? (
                        <Fragment>
                            <Loader />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>{ props.report.firstName + " " + props.report.lastName }: {moment.unix(props.report.submissionTime).format("DD/MM/YYYY")}</h2>

                            <div className="highlights">

                                <div className="thirds sml grn">
                                    <p>Test result: </p>
                                    <h5 className="stat">{props.report.overallResult}</h5>
                                </div>
                                <div className="thirds sml red">
                                    <p>Major errors: </p>
                                    <h5 className="stat">{props.report.majors}</h5>
                                </div>
                                <div className="thirds sml amber">
                                    <p>Minor errors:</p>
                                    <h5 className="stat">{props.report.minors}</h5>
                                </div>

                            </div>

                            { props.report.combinedErrors != null && props.report.combinedErrors.length > 0 && 
                                <Fragment>
                                    <h4>General errors made in the assessment</h4>
                                    <ul className="error-list">
                                        { props.report.combinedErrors.map((error, index) => (
                                            <li key={index}><strong>{ capitaliseFirstLetter(error.ErrorType) }</strong>: {error.ErrorDesc}</li>
                                        ))}
                                    </ul>
                                </Fragment>
                            }

                            { props.locationSlide && 
                                <Fragment>
                                    <h4>Specific Errors made in the test</h4>
                                    <div className="pin-show">
                                        { props.locationCount > 1 && 
                                            <button className="prev" onClick={ (e) => props.changeLocation(e, "prev") }>&lt;</button>
                                        }
                                        {props.locationCount > 1 &&
                                            <button className="nxt" onClick={ (e) => props.changeLocation(e, "next") }>&gt;</button>
                                        }

                                        <div className="img">

                                            <img id="bgImg" src={imgMap[props.location.img]} alt="Background to where pins were placed" />

                                            {props.displayPins &&
                                                <Fragment>
                                                    { props.report.pins[props.locationSlide].map((pin, index) => (
                                                        <div className={ "rslt-pin " + pin.result } key={index} style={{top: pin.yScaled, left: pin.xScaled}}>
                                                            <img className={"rslt-pin-icon " + pin.pinImgClass} alt={ pin.pinTitle } title={ pin.pinTitle + "\n" + pin.reason } src={ "/img/pins/" + pin.pinImg } />
                                                        </div>
                                                    ))}
                                                </Fragment>
                                            }

                                        </div>

                                        
                                    </div>
                                </Fragment>
                            }
                        </Fragment>
                    )}

                </section>
            </div>

        </div>
    )
}

export default Report
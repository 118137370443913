// Core react and plugins
import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';


import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'

// Utilities
import Fetching from './../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import { useCookies } from 'react-cookie';

import HotspotQuestion from '../../components/tests/hotspot-question';

const HostspotQuestionContainer = (props) => {

    let encSecret = process.env.REACT_APP_ENC_KEY;
    const navigate = useNavigate();
    const pinSize = { width: 48, height: 62 }
    const padding = 5;
    
    const [openStatuses, setOpenStatuses] = useState({ proofing: false, trapType: false, trapBait: false, treatmentSecured: false, treatmentType: false, treatmentQuantity: false });
    const [proofings, setProofings] = useState([]);
    const [traps, setTraps] = useState([]);
    const [openTraps, setOpenTraps] = useState([]);
    const [treatType, setTreatType] = useState("covered");
    const [treatments, setTreatments] = useState([]);
    const [openTreatments, setOpenTreatments] = useState([]);
    const [openTreatmentOpts, setOpenTreatmentOpts] = useState([]);
    const [label, setLabel] = useState(false);
    const [pins, setPins] = useState({});
    const [removeLb, setRemoveLb] = useState(false);

    const [authCookie, setCookie, removeCookie] = useCookies(['bcpa-ta']); // eslint-disable-line

    // Handle the opening and closing of the different optin menus
    const toggleSelect = (selectToOpen) => {

        let newStatuses = { ...openStatuses }
        for ( var i in newStatuses ) {
            if (i !== selectToOpen) {
                newStatuses[i] = false;
            } else {
                newStatuses[i] = (newStatuses[i]) ? false : true;
            }
        }
        setOpenStatuses(newStatuses);

    }

    // Handle the selection of an option 
    const selectOption = (e, type, optId) => {
        e.preventDefault();

        var newOpts = [];
        switch (type) {
            case "proofing":
                newOpts = [...proofings];
                break;
            case "trap":
                newOpts = [...traps];
                break;
            default:
                newOpts = [...treatments];
                break;
        }

        if (newOpts.includes(optId)) {
            newOpts = newOpts.filter((item) => item !== optId);
        } else {
            newOpts.push(optId);
        }

        switch (type) {
            case "proofing":
                setProofings(newOpts);
                break;
            case "trap":
                setTraps(newOpts);
                break;
            default:
                setTreatments(newOpts);
                break;
        }

    }

    // Toggle the treatment sections that are open 
    const toggleTreatmentSection = (e, section) => {
        e.preventDefault();
        let newOpenTreatments = [...openTreatments];
        if (newOpenTreatments.includes(section)) {
            newOpenTreatments = newOpenTreatments.filter((item) => item !== section);
        } else {
            newOpenTreatments.push(section);
        }
        setOpenTreatments(newOpenTreatments);
    }

    // Toggle the treatment sections that are open 
    const toggleTreatmentOpt = (e, opt) => {
        e.preventDefault();
        let newOpenTreatmentOpts = [...openTreatmentOpts];
        if (newOpenTreatmentOpts.includes(opt)) {
            newOpenTreatmentOpts = newOpenTreatmentOpts.filter((item) => item !== opt);
        } else {
            newOpenTreatmentOpts.push(opt);
        }
        setOpenTreatmentOpts(newOpenTreatmentOpts);
    }

    // Toggle the open sections on the traps
    const toggleOpenTraps = (e, trap) => {
        e.preventDefault();

        let newOpenTraps = [...openTraps];
        if (newOpenTraps.includes(trap)) {
            newOpenTraps = newOpenTraps.filter((item) => item !== trap);
        } else {
            newOpenTraps.push(trap);
        }
        setOpenTraps(newOpenTraps);

    }

    // Launch a label lightbox for this treqatment 
    const launchLabel = (e, treatment, treatmentName, img) => {
        e.preventDefault();
        setLabel({ id: treatment, name: treatmentName, imgLabel: img });
    }
    const hideLabel = (e) => {
        e.preventDefault();
        setLabel(false);
    }

    // Set up the pins ready for movement 
    const setupPins = () => {

        // Get the size and position of the drop image
        let imgDimension = getDimensionsFromClass("drop-img");

        // Set up the pins for the proofing options
        let newPins = {};
        let posn;
        let existing;
        let moved;
        props.questionData.proofing.forEach((option) => {
            // Check for an existing pin like this
            existing = checkForExistingPin(option.id, 0, 0);

            // If it exists then figure out the location for that pin
            posn = (existing) ? getRealPosn(imgDimension, existing.xPosn, existing.yPosn) : { x: 12, y: 8 };
            moved = (existing) ? true : false;

            // Set the pin up
            newPins["proofing-" + option.id] = {
                id: "proofing-" + option.id,
                top: posn.y, 
                left: posn.x, 
                title: option.name, 
                moved: moved, 
                itemId: option.id,
                optId: false, 
                quanId: false,
                pinImg: option.pinImg
            }
        });

        // Set the Trap pins
        props.questionData.traps.forEach((option) => {
            option.options.opts.forEach((opt) => {
                // Check for an existing pin like this
                existing = checkForExistingPin(option.id, opt.id, 0);

                // If it exists then figure out the location for that pin
                posn = (existing) ? getRealPosn(imgDimension, existing.xPosn, existing.yPosn) : { x: 56, y: 0 };
                moved = (existing) ? true : false;

                // Set the pin up
                newPins["traps-" + opt.id] = {
                    id: "traps-" + opt.id,
                    top: posn.y,
                    left: posn.x,
                    title: option.name + " - " + opt.name,
                    moved: moved,
                    itemId: option.id,
                    optId: opt.id,
                    quanId: false,
                    pinImg: option.pinImg
                }
            });
        });

        // Set the pins for the treatments
        props.questionData.treatments.forEach((option) => {
            option.options.opts.forEach((opt) => {
                opt.quantities.forEach((quant) => {
                    // Check for an existing pin like this
                    existing = checkForExistingPin(option.id, opt.id, quant.id);

                    // If it exists then figure out the location for that pin
                    posn = (existing) ? getRealPosn(imgDimension, existing.xPosn, existing.yPosn) : { x: 56, y: 0 };
                    moved = (existing) ? true : false;

                    // Set the pin up
                    newPins["treatments-" + quant.id] = {
                        id: "treatments-" + quant.id,
                        top: posn.y,
                        left: posn.x,
                        title: option.name + ": " + quant.value + " of " + opt.name,
                        moved: moved,
                        itemId: option.id,
                        optId: opt.id,
                        quanId: quant.id,
                        pinImg: option.pinImg
                    }
                });
            });
        });

        setPins(newPins);
    }

    // Check for existingPin
    const checkForExistingPin = (pinId, optId, quantId) => {

        if (typeof props.existingPins[props.hotspotQuestion.locationId] != "undefined") {
            var pin;
            for (var i in props.existingPins[props.hotspotQuestion.locationId]) {
                pin = props.existingPins[props.hotspotQuestion.locationId][i];
                if (pin.pinId === pinId && pin.pinOptionId === optId && pin.quantId === quantId) {
                    return pin;
                }
            }
        }

        return false;

    }

    // Handle the movement of a pin
    const updatePin = (pinId, left, top) => {
console.log("MOVING PIN");
console.log(pinId);
console.log(left);
console.log(top);
        // Get the relative position of the containing div
        let coords = getCoordsFromClass("drop-img");
        coords.y = coords.y - padding; // Add the padding to thw coordinates so that the pin drop display is correct
        coords.x = coords.x - padding;
        let previous = pins[pinId];
console.log(coords);

        // Calculate where the pin is being dropped (centre of the pin)
        let newPins = { ...pins };
        newPins[pinId].top = top - coords.y;
        newPins[pinId].left = left - coords.x;
        if (!previous.moved) {
            console.log("NOT MOVED");
            newPins[pinId].top = newPins[pinId].top - (pinSize.height / 2);
            newPins[pinId].left = newPins[pinId].left - (pinSize.width / 4);
        }
        newPins[pinId].moved = true;
        setPins(newPins);
console.log(newPins[pinId]);
        // Calculate the position of the pin point (for the back-end processing)
        // Remove the padding that we added earlier to get the correct position stored
        var calcPosn = {
            x: newPins[pinId].left + (pinSize.width / 2) - padding,
            y: newPins[pinId].top + (pinSize.height) - padding
        }

        let imgSize = getDimensionsFromClass("drop-img");
        let normalisedPoints = convertToThousand(calcPosn, imgSize);
console.log(calcPosn);
console.log(normalisedPoints);
        // Trigger the question answer being saved
        saveAnswer(pinId, normalisedPoints);
    }

    const saveAnswer = (pinId, points) => {

        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined" ) {
            // Not logged in so everything is cool
            // flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }
        
        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Get the pin details 
        var pin = pins[pinId];
        if (typeof pin == "undefined") {
            alert("Sorry but there was a problem placing that pin. Please try again.")
            return;
        }

        // Assemble the request data
        var requestData = {
            testType: (props.isPractice) ? "practice" : "real",
            locationId: props.hotspotQuestion.locationId,
            pinId: pin.itemId,
            pinOptionId: (pin.optId) ? pin.optId : 0,
            pinQuantityId: (pin.quanId) ? pin.quanId : 0,
            xPosn: points.x,
            yPosn: points.y
        }
        props.updateExistingPins(props.hotspotQuestion.locationId, requestData);

        // Check the server side to see what the status of this auth is...
        var url = (props.isPractice) ? "/tests/practice/answer" : "/tests/real/answer";
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(requestData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // If successful, then no need to do anything
            updateDropLoc();
            return;

        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            alert("Sorry but there was a problem saving the pin position. Please try again.");
        });

    }

    // Function to convert the coordinates to a percentage, based upon the width and height
    const convertToThousand = (coords, dimensions) => {
        const percentage = {
            x: Math.round((coords.x / dimensions.width) * 1000),
            y: Math.round((coords.y / dimensions.height) * 1000)
        }
        return percentage;
    }

    // Function to convert the coordinates from a percentage to real coordinates
    const getRealPosn = (dimensions, xPosn, yPosn) => {
        const coords = {
            x: Math.round((xPosn / 1000) * dimensions.width) - (pinSize.width / 2) + padding,
            y: Math.round((yPosn / 1000) * dimensions.height) - pinSize.height + padding
        }
        return coords;
    }

    // Function to get the x and y coordinates of a div on screen based upon its class name
    const getCoordsFromClass = (className) => {
        const element = document.getElementsByClassName(className)[0];
        const coords = element.getBoundingClientRect();
        return coords;
    }

    // Function to the the width and height of a div based upon its class name (in pixels)
    const getDimensionsFromClass = (className) => {
        const element = document.getElementsByClassName(className)[0];
        const dimensions = {
            width: element.offsetWidth,
            height: element.offsetHeight
        }
        return dimensions;
    }

    // Remove a pin 
    const removePin = (e, pinId) => {
        if (e) e.preventDefault();
        if (!e || e.type === 'contextmenu') {
            setRemoveLb(pinId);
        }
    }

    // Cancel pin removal
    const cancelRemoval = (e) => {
        if (e) e.preventDefault();
        setRemoveLb(false);
    }

    // Confirm pin removal
    const completeRemoval = (e, pinId) => {
        e.preventDefault();
        
        // Update the existing pins
        var newPins = { ...pins };
        var pin = false;
        for (var i in newPins) {
            if (newPins[i].id === pinId) {
                pin = newPins[i];
                newPins[i].moved = false;
                newPins[i].left = (newPins[i].optId) ? 56 : 12;
                newPins[i].top = (newPins[i].optId) ? 0 : 8;
                break;
            }
        }
        setPins(newPins);

        // Update on the server side
        // If there is no cookie, then they are not logged in
        if (!authCookie || Object.keys(authCookie).length === 0 || typeof authCookie['bcpa-ta'] == "undefined" || authCookie['bcpa-ta'] === "undefined") {
            // Not logged in so everything is cool
            // flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            return;
        }

        // There is a cookie, so check that it is a valid cookie
        // Decrypt the cookie itself
        var reformData = CryptoJS.AES.decrypt(authCookie['bcpa-ta'], encSecret)
        reformData = JSON.parse(reformData.toString(CryptoJS.enc.Utf8));

        // Get the pin details 
        if (!pin) {
            alert("Sorry but there was a problem removing that pin. Please try again.")
            return;
        }

        // Assemble the request data
        var requestData = {
            testType: (props.isPractice) ? "practice" : "real",
            locationId: props.hotspotQuestion.locationId,
            pinId: pin.itemId,
            pinOptionId: (pin.optId) ? pin.optId : 0,
            pinQuantityId: (pin.quanId) ? pin.quanId : 0
        }

        // Check the server side to see what the status of this auth is...
        var url = (props.isPractice) ? "/tests/practice/clear" : "/tests/real/clear";
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': reformData.apiToken,
                'api-key': reformData.apiKey
            },
            body: JSON.stringify(requestData)
        })
            .then(Fetching.statusCheck)
            .then(Fetching.jsonExtract)
            .then(function (data) {

                // If successful, then no need to do anything
                updateDropLoc();

            })
            .catch(function (error) {
                // Invalid token - clear the cookie and redirect to the login page
                alert("Sorry but there was a problem saving the pin position. Please try again.");
            });


        // Update further up the chain?! Existing Pins etc.
        props.removeExistingPin(props.hotspotQuestion.locationId, requestData);

    }

    // Function to update the last location of a pin drop / removal
    const updateDropLoc = () => {
        let lastLoc = (props.lastPinDropLoc) ? props.lastPinDropLoc.split("-") : false;
        if (lastLoc && parseInt(lastLoc[0]) === props.hotspotQuestion.locationId) {
            let newInc = parseInt(lastLoc[1]) + 1;
            props.setLastPinDropLoc(props.hotspotQuestion.locationId + "-" + newInc);
        } else {
            props.setLastPinDropLoc(props.hotspotQuestion.locationId + "-1");
        }
    }

    /*eslint-disable */
    useEffect(() => {
        setupPins();
    }, []);
    /*eslint-enable */

    return (
        <DndProvider backend={ isMobile ? TouchBackend : HTML5Backend } options={{ enableMouseEvents: false }}>
            <HotspotQuestion
                openStatuses={ openStatuses }
                toggleSelect={toggleSelect }
                proofings={proofings}
                traps={traps}
                pins={ pins }
                updatePin={ updatePin }
                treatments={ treatments }
                selectOption={ selectOption }
                treatType={ treatType }
                setTreatType={ setTreatType }
                toggleTreatmentSection={ toggleTreatmentSection }
                openTreatments={ openTreatments }
                openTraps={ openTraps }
                toggleOpenTraps={toggleOpenTraps}
                toggleTreatmentOpt={ toggleTreatmentOpt }
                openTreatmentOpts={ openTreatmentOpts }
                launchLabel={ launchLabel }
                label={ label }
                hideLabel={ hideLabel }
                removePin={ removePin }
                removeLb={ removeLb }
                cancelRemoval={ cancelRemoval }
                completeRemoval={ completeRemoval }
                {...props}
            />
        </DndProvider>
    );
}

export default HostspotQuestionContainer;
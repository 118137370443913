import React, { Component } from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom'
import LoginContainer from './../containers/users/auth/login-container';
import LogoutContainer from './../containers/users/auth/logout-container';
import ForgottenPwContainer from './../containers/users/auth/forgotten-pw-container';
import SetPwContainer from './../containers/users/auth/set-pw-container';

import TaLogo from './../assets/img/bpca/ta-logo.png';
import BpcaLogo from './../assets/img/bpca/bpca-logo.png';

class Routing extends Component {
 
    render () {
        if (this.props.rdr) {
            return <Navigate  to={ this.props.rdr } />
        }
       
        return (
            <div className="login">
                <div className="sidebar">
                    <img src={ TaLogo } alt="TechAssure logo" id="ta-logo" />
                    <img src={ BpcaLogo } alt="British Pest Control Association logo" id="bpca-logo" />
                    <h1>Welcome to <span>TechAssure</span></h1>
                    <p>TechAssure is a BPCA accredited virtual practical assessment.</p>
                </div>
                <div className="core">
                    <div className="login-content">
                        <div className='logo-sxn mobile'>
                            <img src={BpcaLogo} alt="BPCA logo" />
                            <h2>TechAssure</h2>
                        </div>
                        <Routes>
                            <Route path='forgotten-password' element={ <ForgottenPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey/:setType' element={ <SetPwContainer/> } />
                            <Route path='set-password/:resetToken/:resetKey' element={ <SetPwContainer/> } />
                            <Route path='logout' element={ <LogoutContainer/> } />
                            <Route index element={<LoginContainer/>} />
                            
                        </Routes>
                    </div>
                </div>
            </div>
        );
    }
}

export default Routing;
